import AuthActionTypes from './actionType';
const INITIAL_STATE = {
  user: { role: null },
  loading: false,
  error: { code: '', message: '', field: '' },
  isAuthenticated: false,
  userDetail: { accounts: [], user_info: {} },
  message: '',
};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AuthActionTypes.AUTH_LOGIN_REQUEST:
      return { ...state, loading: true };
    case AuthActionTypes.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false,
        isAuthenticated: true,
        userDetail: INITIAL_STATE.userDetail,
        message: action.message,
      };
    case AuthActionTypes.AUTH_LOGIN_CANCEL:
      return {
        ...state,
        user: action.user,
        loading: false,
        isAuthenticated: false,
        error: action.error,
        userDetail: INITIAL_STATE.userDetail,
      };
    case AuthActionTypes.AUTH_LOGIN_FAIL:
      return {
        ...state,
        user: action.user,
        loading: false,
        isAuthenticated: false,
        error: action.error,
        userDetail: INITIAL_STATE.userDetail,
      };
    case AuthActionTypes.AUTH_LOGOUT_REQUEST:
      return { ...INITIAL_STATE };
    case AuthActionTypes.AUTH_LOGOUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        user: { role: null },
        loading: action.loading,
        isAuthenticated: false,
        error: action.error,
        userDetail: INITIAL_STATE.userDetail,
      };
    case AuthActionTypes.AUTH_LOGIN_USER_SET:
      return {
        ...state,
        user: action.user,
        loading: action.loading,
        isAuthenticated: false,
        error: action.error,
        userDetail: INITIAL_STATE.userDetail,
      };
    case AuthActionTypes.AUTH_LOGOUT_FAIL:
      return { ...state };
    case AuthActionTypes.AUTH_USER_DETAIL_REQUEST:
      return { ...state };
    case AuthActionTypes.AUTH_USER_DETAIL_SUCCESS:
      return { ...state, userDetail: action.userDetail, role: action.role };

    case AuthActionTypes.SIGN_UP_SUCCESS:
      return { ...state, message: action.message };

    case AuthActionTypes.SIGNUP_FAIL:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
