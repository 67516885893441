import React from 'react';
import { TextInput } from '../../../../NewComponents';
import { useTranslation } from 'react-i18next';

export const Questions = ({
  questions,
  setQuestion,
  country,
  setCountry,
  company,
  error,
}) => {
  const { t } = useTranslation();

  const handleQuestionAnswer = (e, key) => {
    e.persist();
    let newQuestions = [...questions];
    newQuestions[key] = {
      question: questions[key].question,
      answer: e.target.value,
    };
    setQuestion(newQuestions);
  };

  return (
    <div className="personal_info">
      <TextInput
        name="country"
        placeholder={t('Country')}
        value={country}
        showLabel={true}
        onChange={(e) => setCountry(e.target.value)}
        icon="/images/login/Group.svg"
        error={error.country ? ' ' : ''}
      />
      <TextInput
        name="organization"
        placeholder={t('Organization')}
        value={company}
        showLabel={true}
        icon="/images/login/Group.svg"
        readOnly
      />
      {questions.map((q, key) => {
        return (
          <div style={{ marginTop: '5px', marginBottom: '5px' }} key={key}>
            <label>{q.question}</label>
            <TextInput
              type="textarea"
              value={q.answer}
              onChange={(e) => handleQuestionAnswer(e, key)}
              noIcon
            />
          </div>
        );
      })}
    </div>
  );
};
