import CapActionTypes from './actionTypes';
const INITIAL_STATE = {
  pillars: [],
  selectedPillar: {},
  selectedLayers: [],
  selectedType: {},
  dataList: [],
  loading: false,
  message: '',
  sdfData: [],
  problemData: [],
  projectData: [],
  oppurtunityData: [],
  facilityData: [],

  variables: [],
  selectedVariable: {},
  styles: [],

  filteredVariableData: [],
  chartData: {},

  error: { code: '', message: '', field: '' },
};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CapActionTypes.CAP_PILLAR_REQUEST:
      return { ...state, loading: true };
    case CapActionTypes.CAP_PILLAR_SUCCESS:
      return { ...state, pillars: action.pillars, loading: false };
    case CapActionTypes.CAP_PILLAR_SELECT:
      return {
        ...state,
        selectedPillar: action.params,
        loading: false,
        styles: [],
      };
    case CapActionTypes.CAP_TYPE_SELECT:
      return { ...state, selectedType: action.params, loading: false };
    case CapActionTypes.CAP_DATA_LIST_REQUEST:
      return { ...state, loading: true };
    case CapActionTypes.CAP_DATA_LIST_SUCCESS:
      return { ...state, dataList: action.data, loading: false };
    case CapActionTypes.CAP_SET_SELECED_DATA:
      return { ...state, selectedLayers: action.layers, loading: false };
    case CapActionTypes.CAP_SDF_DATA_REQUEST:
      return { ...state, loading: false };
    case CapActionTypes.CAP_SDF_DATA_SUCCESS: {
      let sdfData = [...state.sdfData];
      let sdf = sdfData.hasOwnProperty(action.pillar)
        ? sdfData[action.pillar]
        : [];
      sdf[action.sdf] = action.data;

      sdfData[action.pillar] = sdf;
      console.log('dsfasdf', sdfData);
      return { ...state, sdfData: sdfData, loading: false };
    }
    case CapActionTypes.CAP_PROBLEM_DATA_SUCCESS: {
      let problemData = [...state.problemData];
      let problem = problemData.hasOwnProperty(action.pillar)
        ? problemData[action.pillar]
        : [];
      problem[action.problem] = action.data;
      problemData[action.pillar] = problem;
      return { ...state, problemData: problemData, loading: false };
    }
    case CapActionTypes.CAP_PROJECT_DATA_SUCCESS: {
      let projectData = [...state.projectData];
      let project = projectData.hasOwnProperty(action.pillar)
        ? projectData[action.pillar]
        : [];
      project[action.project] = action.data;
      projectData[action.pillar] = project;
      return { ...state, projectData: projectData, loading: false };
    }
    case CapActionTypes.CAP_VARIABLES_REQUEST:
      return { ...state, loading: true };

    case CapActionTypes.CAP_VARIABLES_FAIL:
      return { ...state, loading: false, error: action.error };

    case CapActionTypes.CAP_VARIABLES_SUCCESS:
      return { ...state, loading: false, variables: action.variables };

    case CapActionTypes.SET_CAP_VARIABLES:
      return { ...state, selectedVariable: action.params };

    case CapActionTypes.GET_STYLES_REQ:
      return { ...state, loading: true };

    case CapActionTypes.GET_STYLES_SUC:
      return { ...state, styles: action.data, loading: false };

    case CapActionTypes.SET_STYLES:
      return { ...state, styles: action.params, loading: false };

    case CapActionTypes.SET_FILTERED_VARIABLE:
      return { ...state, filteredVariableData: action.params };

    case CapActionTypes.SET_CHART_DATA:
      return { ...state, chartData: action.params };

    default:
      return state;
  }
}
