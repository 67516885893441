import Api from "./Api";
import MainAPI from "../../config/ownApi";
import errorParse from "../lib/error";
import GeoApi from "../../config/GeoApi";

function getPillars(params) {
  const res = Api.get("/pillars")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function getDataList(params) {
  console.log("datalist", params);
  const res = Api.get("/" + params.type + "ByPillar", {
    params: { pillar: params.pillar },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function getSdfData(params) {
  const res = MainAPI.get("/sdfDataById", { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function getProblemData(params) {
  const res = MainAPI.get("/problemDataById", { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function getProjectData(params) {
  const res = MainAPI.get("/projectDataById", { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function getCapVariables(params) {
  const res = GeoApi.get("/getCapVariablesNew", { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function getStyles(params) {
  const res = GeoApi.get("/getStyleFromVariable", { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
export const CapService = {
  getPillars,
  getDataList,
  getSdfData,
  getProblemData,
  getProjectData,
  getCapVariables,
  getStyles,
};
