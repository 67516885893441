export default function errorParse(error, custom = false, field = null) {
  if (custom) {
    return {
      status: 'error',
      error: { code: 404, message: error, field: field },
    };
  } else if (error.response) {
    let err = error.response.data.error;

    let message = err.message.replace('.', '').replace('!', '');
    message = message.split(' ').join('_');
    let code = err.code;
    return {
      status: 'error',
      error: { code: code, message: message, field: err.field },
    };
  } else {
    return {
      status: 'error',
      error: { code: 404, message: 'please check api point or network' },
    };
  }
}
