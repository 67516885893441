import Api from '../../config/Api';
import errorParse from '../lib/error';
import axios from 'axios';
import GeoApi from '../../config/GeoApi';

function loginUser(params) {
  const res = Api.post('/login', params)
    .then((response) => {
      let data = response;
      return data;
    })
    .catch((error) => {
      console.log(errorParse(error));
      return errorParse(error);
    });
  return res;
}
function logoutUser() {
  const res = Api.post('/logout')
    .then(function (response) {
      let data = response;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function userDetail(params) {
  const res = Api.get('/user/detailAll', { params })
    .then(function (response) {
      return response;
    })
    .catch((error) => {
      console.log('error', errorParse(error));
    });
  return res;
}
function getAllRoles() {
  const res = Api.get('/common/role/all')
    .then(function (response) {
      let data = response;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

export const getOauthToken = (url, data) => {
  const res = axios({
    method: 'post',
    url,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then((response) => {
      console.log({ response });
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
  return res;
};

const registerByApp = (params) => {
  const res = GeoApi.post('register', params)
    .then(function (response) {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
};

const registerQuestionAns = (params) => {
  const res = Api.post('user/registerQuestionAns', params)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return errorParse(e);
    });
  return res;
};

export const AuthService = {
  loginUser,
  logoutUser,
  userDetail,
  getAllRoles,
  registerByApp,
  registerQuestionAns,
};
