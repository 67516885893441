import React from 'react';
import { roundUpDecimals } from './helpers';

const Legends = ({ color, legend, imgUrl, value }) => {
  return (
    <div className={'legend-container'}>
      <div>
        <svg
          width="20"
          height="20"
          fill={color}
          style={{ border: '1px solid black', marginRight: '5px' }}
        >
          <rect width="20" height="20" />
        </svg>
      </div>
      {imgUrl && <img src={imgUrl} alt="legend" className="legend-img" />}
      <div className="legendWithValue">
        <span>{roundUpDecimals(legend)}</span>
        {value && <span>{value}</span>}
      </div>
    </div>
  );
};
export default Legends;
