// import {Text} from "../../components/weatherForecast/weatherForecast.web";

const en = {
  label: "En",
  value: "en",
  translation: {
    welcome_message: "Hello {{name}}, You are logged in as {{role}}",
  },
};
export default en;
