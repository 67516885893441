import GisActionTypes from "./actionTypes";
import { take, call, put, actionChannel, all } from "redux-saga/effects";
import { GisService } from "./Service";

export function* gisCountry() {
  while (true) {
    const { params } = yield take(GisActionTypes.GIS_COUNTRY_REQUEST);
    //console.log(params);
    const { data, error } = yield call(GisService.getCountry, params);

    if (!error) {
      console.log("data", data);
      yield put({ type: GisActionTypes.GIS_COUNTRY_SUCCESS, country: data });
    }
  }
}
export function* gisProvinces() {
  while (true) {
    const { params } = yield take(GisActionTypes.GIS_PROVINCES_REQUEST);
    //console.log(params);
    const { data, error } = yield call(GisService.getProvinces, params);

    if (!error) {
      console.log("data", data);
      yield put({ type: GisActionTypes.GIS_PROVINCES_SUCCESS, list: data });
    }
  }
}
export function* gisCommunes() {
  while (true) {
    const { params } = yield take(GisActionTypes.GIS_COMMUNES_REQUEST);
    //console.log(params);
    const { data, error } = yield call(GisService.getCommunes, params);

    if (!error) {
      yield put({ type: GisActionTypes.GIS_COMMUNES_SUCCESS, list: data });
    }
  }
}
export function* gisZones() {
  while (true) {
    const { params } = yield take(GisActionTypes.GIS_ZONES_REQUEST);
    //console.log(params);
    const { data, error } = yield call(GisService.getZones, params);

    if (!error) {
      yield put({ type: GisActionTypes.GIS_ZONES_SUCCESS, list: data });
    }
  }
}
export function* gisCollines() {
  while (true) {
    const { params } = yield take(GisActionTypes.GIS_COLLINES_REQUEST);
    //console.log(params);
    const { data, error } = yield call(GisService.getCollines, params);

    if (!error) {
      yield put({ type: GisActionTypes.GIS_COLLINES_SUCCESS, list: data });
    }
  }
}

export function* gisLocalities() {
  while (true) {
    const { params } = yield take(GisActionTypes.GIS_LOCALITIES_REQUEST);
    //console.log(params);
    const { data, error } = yield call(GisService.getLocalities, params);

    if (!error) {
      yield put({ type: GisActionTypes.GIS_LOCALITIES_SUCCESS, list: data });
    }
  }
}
export function* gisGroups() {
  while (true) {
    const { params } = yield take(GisActionTypes.GIS_GROUPS_REQUEST);
    //console.log(params);
    const { data, error } = yield call(GisService.getGroups, params);

    if (!error) {
      yield put({ type: GisActionTypes.GIS_GROUPS_SUCCESS, list: data });
    }
  }
}

export function* gisCapData(params) {
  //  const {params}=yield take(GisActionTypes.GIS_CAP_DATA_REQUEST);
  //console.log('sdfa',params);
  const { data, error } = yield call(GisService.getGisCapData, params);

  if (!error) {
    var geojson = {
      type: "FeatureCollection",
      features: [],
    };
    data.forEach((row) => {
      switch (params.type) {
        case "sdf":
          var layer = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [row.longitude, row.latitude],
            },
            properties: {
              ...row,
              country: params.country,
              name: row.sdf,
              sdfid: row.sdfid,
              pillarid: row.pillar_id,
            },
          };
          geojson["name"] = data[0].sdf;
          geojson["sdfid"] = data[0].sdfid;
          geojson["pillarid"] = data[0].pillar_id;
          geojson.features.push(layer);
          break;
        case "problems":
          var layer1 = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [row.longitude, row.latitude],
            },
            properties: {
              ...row,
              country: params.country,
              name: row.problem,
              sdfid: row.pid,
              pillarid: row.pillar_id,
            },
          };

          geojson["name"] = data[0].problem;
          geojson["problemid"] = data[0].pid;
          geojson["pillarid"] = data[0].pillar_id;
          geojson.features.push(layer1);
          break;
        default:
          break;
      }
    });

    yield put({ type: GisActionTypes.GIS_CAP_DATA_SUCCESS, data: geojson });
  }
}

export function* watchEveryGisCapData() {
  // yield takeEvery(GisActionTypes.GIS_CAP_DATA_REQUEST,gisCapData)
  const requestChan = yield actionChannel(GisActionTypes.GIS_CAP_DATA_REQUEST);
  while (true) {
    const { params } = yield take(requestChan);
    yield call(gisCapData, params);
  }
}
export default function* GisSaga() {
  //console.log('saga auth');
  yield all([
    gisCountry(),
    watchEveryGisCapData(),
    gisProvinces(),
    gisCommunes(),
    gisZones(),
    gisCollines(),
    gisLocalities(),
    gisGroups(),
  ]);
}
