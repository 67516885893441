import GeoActionTypes from './actionTypes';
export function getGeoCountry() {
  return {
    type: GeoActionTypes.GEO_COUNTRY_SELECT_REQUEST,
    loading: true,
  };
}
export function getGeoProvinces(params) {
  return {
    type: GeoActionTypes.GEO_PROVINCE_LIST_REQUEST,
    params,
  };
}
export function getGeoCommunes(params) {
  return {
    type: GeoActionTypes.GEO_COMMUNE_LIST_REQUEST,
    params,
  };
}
export function getGeoZones(params) {
  return {
    type: GeoActionTypes.GEO_ZONE_LIST_REQUEST,
    params,
  };
}
export function getGeoCollines(params) {
  return {
    type: GeoActionTypes.GEO_COLLINE_LIST_REQUEST,
    params,
  };
}
export function getGeoLocalities(params) {
  return {
    type: GeoActionTypes.GEO_LOCALITY_LIST_REQUEST,
    params,
  };
}
export function getGeoGroups(params) {
  return {
    type: GeoActionTypes.GEO_GROUP_LIST_REQUEST,
    params,
  };
}
export function selectCountry(params) {
  return {
    type: GeoActionTypes.GEO_COUNTRY_SELECTED,
    selected: params,
  };
}
export function selectProvince(params) {
  return {
    type: GeoActionTypes.GEO_PROVINCE_SELECTED,
    selected: params,
  };
}
export function selectCommune(params) {
  return {
    type: GeoActionTypes.GEO_COMMUNE_SELECTED,
    selected: params,
  };
}
export function selectZone(params) {
  return {
    type: GeoActionTypes.GEO_ZONE_SELECTED,
    selected: params,
  };
}
export function selectColline(params) {
  return {
    type: GeoActionTypes.GEO_COLLINE_SELECTED,
    selected: params,
  };
}
export function selectLocality(params) {
  return {
    type: GeoActionTypes.GEO_LOCALITY_SELECTED,
    selected: params,
  };
}

export function getArea1(params) {
  return {
    type: GeoActionTypes.GET_AREA1_REQUEST,
    params,
  };
}
export function setArea1(params) {
  return {
    type: GeoActionTypes.SET_AREA1_SELECTED,
    data: params,
  };
}

export function getArea2(params) {
  return {
    type: GeoActionTypes.GET_AREA2_REQUEST,
    params,
  };
}
export function setArea2(params) {
  return {
    type: GeoActionTypes.SET_AREA2_SELECTED,
    data: params,
  };
}

export function getArea3(params) {
  return {
    type: GeoActionTypes.GET_AREA3_REQUEST,
    params,
  };
}
export function setArea3(params) {
  return {
    type: GeoActionTypes.SET_AREA3_SELECTED,
    data: params,
  };
}

export function getArea4(params) {
  return {
    type: GeoActionTypes.GET_AREA4_REQUEST,
    params,
  };
}
export function setArea4(params) {
  return {
    type: GeoActionTypes.SET_AREA4_SELECTED,
    data: params,
  };
}

export function getCrops() {
  return {
    type: GeoActionTypes.GET_CROP_REQUEST,
  };
}

export function setCrops(data) {
  return {
    type: GeoActionTypes.SET_CROPS,
    data,
  };
}

export function setSelectedCrop(data) {
  return {
    type: GeoActionTypes.SET_SELECTED_CROP,
    data,
  };
}

export function getActivityMonitorMenus() {
  return {
    type: GeoActionTypes.GET_AGRICOACH_ACTIVITY_MENUS_REQ,
  };
}

export function getCropValidationBankList() {
  return {
    type: GeoActionTypes.GET_CROP_VALIDATION_LIST_REQ,
  };
}
export function setCropValidationBankList(data) {
  return {
    type: GeoActionTypes.SET_CROP_VALIDATION_LIST_REQ,
    data,
  };
}
export function setSelectedBankCode(data) {
  return {
    type: GeoActionTypes.SET_SELECTED_BANK_CODE,
    data,
  };
}

export function setSelectedValidationVariable(data) {
  return {
    type: GeoActionTypes.SET_SELECTED_VALIDATION_VARIABLE,
    data,
  };
}

export function setSelectedMenu(data) {
  return {
    type: GeoActionTypes.SET_SELECTED_MENU,
    data,
  };
}

export function setSelectedLand(data) {
  return {
    type: GeoActionTypes.SET_SELECTED_LAND,
    data,
  };
}

export function setSelectedWeather(data) {
  return {
    type: GeoActionTypes.SET_SELECTED_WEATHER,
    data,
  };
}

export function setSelectedSetting(data) {
  return {
    type: GeoActionTypes.SET_SELECTED_SETTING,
    data,
  };
}

export function getWeatherDataAtPixel(params) {
  return {
    type: GeoActionTypes.GET_WEATHER_DATA_REQ,
    params,
  };
}

export function setWeatherDataAtPixel() {
  return {
    type: GeoActionTypes.SET_WEATHER_DATA,
  };
}

export function clearAllGeoState() {
  return {
    type: GeoActionTypes.CLEAR_ALL,
  };
}

export function getRainFall(params) {
  return {
    type: GeoActionTypes.GET_RAIN_FALL_DATA_REQ,
    params,
  };
}

export function setRainFall() {
  return {
    type: GeoActionTypes.SET_RAIN_FALL_DATA,
  };
}

export function setSelectedMonitor(data) {
  return {
    type: GeoActionTypes.SET_SELECTED_MONITOR,
    data,
  };
}

export function setSelectedMonitorItem(data) {
  return {
    type: GeoActionTypes.SET_SELECTED_MONITOR_ITEM,
    data,
  };
}

export function setSelectedFertilizer(data) {
  return {
    type: GeoActionTypes.SET_SELECTED_FERTILIZER,
    data,
  };
}

export function getFertilizers() {
  return {
    type: GeoActionTypes.GET_FERTILIZERS_REQUEST,
  };
}
