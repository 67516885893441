const AuthActionTypes = {
  AUTH_LOGIN_REQUEST: 'auth.login.request',
  AUTH_LOGIN_SUCCESS: 'auth.login.success',
  AUTH_LOGIN_FAIL: 'auth.login.fail',
  AUTH_LOGIN_CANCEL: 'auth.login.cancel',
  AUTH_LOGOUT_REQUEST: 'auth.logout.request',
  AUTH_LOGOUT_SUCCESS: 'auth.logout.success',
  AUTH_LOGOUT_FAIL: 'auth.logout.fail',
  AUTH_LOGIN_USER_SET: 'auth.login.set.refresh',
  AUTH_USER_DETAIL_REQUEST: 'auth.user.detail.request',
  AUTH_USER_DETAIL_SUCCESS: 'auth.user.detail.success',

  SIGN_UP_REQUEST: 'sign.up.request',
  SIGN_UP_SUCCESS: 'sign.up.success',
  SIGNUP_FAIL: 'signup.fail',
};
export default AuthActionTypes;
