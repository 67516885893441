import React from "react";
import ReactTooltip from "react-tooltip";

const MenuButton = (props) => {
  return (
    <div
      className={`menu-button  ${props.className}`}
      data-for="main"
      data-tip={props.label}
    >
      <img
        src={props.image}
        alt={props.label}
        id={props.id}
        height="30px"
        width="30px"
      />

      <ReactTooltip id="main" place="top" type="dark" effect="solid" />
    </div>
  );
};

export default MenuButton;
