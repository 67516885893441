import AuthActionTypes from './actionType';
import errorParse from '../lib/error';

export function login(params) {
  if (params.username === '') {
    return {
      type: AuthActionTypes.AUTH_LOGIN_FAIL,
      loading: false,
      error: errorParse('this field is required', true, 'username').error,
    };
  }
  if (params.password === '') {
    return {
      type: AuthActionTypes.AUTH_LOGIN_FAIL,
      loading: false,
      error: errorParse('this field is required', true, 'password').error,
    };
  }
  // if (params.role === "") {
  //   return {
  //     type: AuthActionTypes.AUTH_LOGIN_FAIL,
  //     loading: false,
  //     error: errorParse("this field is required", true, "role").error,
  //   };
  // }
  return {
    type: AuthActionTypes.AUTH_LOGIN_REQUEST,
    loading: true,
    params,
  };
}
export function logout() {
  return {
    type: AuthActionTypes.AUTH_LOGOUT_REQUEST,
    loading: true,
  };
}
export function setUser(user) {
  return {
    type: AuthActionTypes.AUTH_LOGIN_USER_SET,
    loading: false,
    user: user,
  };
}
export function getUserDetail(user_id) {
  return {
    type: AuthActionTypes.AUTH_USER_DETAIL_REQUEST,
    umva_id: user_id,
  };
}

export function signup(regParams, questions) {
  return {
    type: AuthActionTypes.SIGN_UP_REQUEST,
    params: { regParams, questions },
  };
}
