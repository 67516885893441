import axios from "axios";
const Api = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_CAP_API_URL + "/api",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  let api = axios.create(defaultOptions);
  api.interceptors.request.use(
    function (config) {
      return config;
    },
    function (err) {
      console.log("reqeuist", err);
      return Promise.reject(err);
    }
  );
  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const {
        // config,
        response: { status },
      } = error;
      console.log("response", status);
      return Promise.reject(error);
    }
  );
  return api;
};
export default Api();
