import { Style, Stroke, Fill } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import VectorSource from 'ol/source/Vector';
import { WMTS, ImageWMS, TileWMS } from 'ol/source';
import { get } from 'ol/proj';
import { getWidth, getTopLeft } from 'ol/extent';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import Image from 'ol/layer/Image';

export const defaultStyle = [
  new Style({
    stroke: new Stroke({
      color: '#FF6600',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 0, 0)',
    }),
  }),
];

export const geoserverUrl = process.env.REACT_APP_GEOSERVER_URL;
// export const geoserverUrl = process.env.REACT_APP_UMVA_NEPAL_SERVER;

export const wfsUrl = geoserverUrl + '/wfs';

export const matrixSet = 'EPSG:4326';
export const url = geoserverUrl + '/gwc/service/wmts';
export const projection = get(matrixSet);
export const projectionExtent = projection.getExtent();
export let resolutions = new Array(14);
export let matrixIds = new Array(14);
export const size = getWidth(projectionExtent) / 256;
export const format = 'image/png';
for (var z = 0; z < 14; ++z) {
  // generate resolutions and matrixIds arrays for this WMTS
  // matrixIds[z] = "EPSG:4326:" + z;

  resolutions[z] = size / Math.pow(2, z);
  matrixIds[z] = 'EPSG:4326:' + (z - 1);
  // matrixIds[z] = z;
}

export const constructSource = (layer) => {
  var source = new WMTS({
    crossOrigin: 'Anonymous',
    url,
    layer,
    matrixSet,
    format,

    projection: projection,
    tileGrid: new WMTSTileGrid({
      // tileSize: [256, 256],
      origin: getTopLeft(projectionExtent),
      resolutions: resolutions,
      matrixIds: matrixIds,
    }),
    wrapX: true,
  });
  return source;
};

const constructTileWmsSource = (layer) => {
  const url = geoserverUrl + '/umva_geo/wms';
  return new TileWMS({
    url,
    params: {
      FORMAT: format,
      VERSION: '1.1.1',
      tiled: true,
      LAYERS: layer,
      exceptions: 'application/vnd.ogc.se_inimage',
      tilesOrigin: 28.991096496582 + ',' + -4.48080205917358,
    },
  });
};

export const vectorLayer = new VectorLayer({
  source: new VectorSource({}),
  // style: defaultStyle,
  opacity: 0.8,
  name: 'vector',
});
export const groupVector = new VectorLayer({
  source: new VectorSource({}),
  opacity: 0.8,
});

export const landscapeVector = new VectorLayer({
  source: new VectorSource({}),
  style: defaultStyle,
  opacity: 0.8,
  name: 'Landscape',
});

export const weatherVector = new VectorLayer({
  source: new VectorSource({}),
  // opacity: 0.8,
  name: 'weather',
});

export const firstLayer = new TileLayer();

export const commentsLayer = new TileLayer({
  source: constructSource('umva_geo:area4_correctness'),
  visible: false,
});

export const spiMeanLayer = new TileLayer({
  source: constructSource('umva_geo:spi_mean'),
  name: 'spi_mean',
  visible: false,
});

// export const avg_rainfall_area1 = new TileLayer({
//   source: constructSource(`umva_geo:avg_rainfall_area1`),
//   name: 'avg_rainfall_area1',
//   visible: false,
// });
// export const avg_rainfall_area2 = new TileLayer({
//   source: constructSource(`umva_geo:avg_rainfall_area2`),
//   name: 'avg_rainfall_area1',
//   visible: false,
// });
// export const avg_rainfall_area3 = new TileLayer({
//   source: constructSource(`umva_geo:avg_rainfall_area3`),
//   name: 'avg_rainfall_area1',
//   visible: false,
// });
// export const avg_rainfall_area4 = new TileLayer({
//   source: constructSource(`umva_geo:avg_rainfall_area4`),
//   name: 'avg_rainfall_area1',
//   visible: false,
// });

export const weather = new Image({
  source: new ImageWMS({
    ratio: 1,
    url: geoserverUrl + '/umva_geo/wms',
    params: {
      FORMAT: format,
      VERSION: '1.1.1',
      LAYERS: 'umva_geo:avg_weather_today',
      exceptions: 'application/vnd.ogc.se_inimage',
    },
  }),
  visible: false,
});

export const area0Layer = new TileLayer({
  source: constructSource('umva_geo:area0'),
  visible: false,
});

export const area1Layer = new TileLayer({
  visible: false,
  source: constructTileWmsSource('umva_geo:area1'),
});

export const area2Layer = new TileLayer({
  visible: false,
  source: constructTileWmsSource('umva_geo:area2'),
});
export const area3Layer = new TileLayer({
  source: constructTileWmsSource('umva_geo:area3'),
  visible: false,
});
export const area4Layer = new TileLayer({
  source: constructTileWmsSource('umva_geo:area4'),
  visible: false,
});

export const crops = new Image({
  source: new ImageWMS({
    ratio: 1,
    url: geoserverUrl + '/umva_geo/wms',
    params: {
      FORMAT: format,
      VERSION: '1.1.1',
      LAYERS: 'umva_geo:1',
      exceptions: 'application/vnd.ogc.se_inimage',
    },
  }),
  visible: false,
});

export const landscapes = new Image({
  source: new ImageWMS({
    ratio: 1,
    url: geoserverUrl + '/umva_geo/wms',
    params: {
      FORMAT: format,
      VERSION: '1.1.1',
      LAYERS: 'umva_geo:elevation',
      exceptions: 'application/vnd.ogc.se_inimage',
    },
  }),
  visible: false,
});
