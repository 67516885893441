import React from 'react';
import { Maps } from './Map';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getStyles,
  setStyles,
  setFilteredVariableData,
  setChartData,
} from '../../Cap/action';
import {
  getWeatherDataAtPixel,
  getRainFall,
  setRainFall,
} from '../../Geo/action';
import './map.scss';
import 'ol/ol.css';

import { equalTo as equalToFilter, and as andFilter } from 'ol/format/filter';

const MapContainer = (props) => <Maps props={props} />;

const mapStateToProps = (state) => {
  const {
    area1,
    area2,
    area3,
    area4,
    loading,
    selectedCrop,
    selectedLand,
    address,
    selectedWeather,
    selectedSetting,
    selectedMenu,
    selectedBankCode,
    selectedValidationVariable,
    monitor,
    monitorItem,
    selectedFertilizer,
  } = state.geo;
  const { selectedLevel, showRaster } = state.map;
  const { selectedVariable, styles, selectedPillar, selectedType } = state.cap;

  let selectedData = {};

  if (selectedVariable.column_name) {
    selectedData = selectedVariable;
  } else if (selectedValidationVariable.column_name) {
    selectedData = { ...selectedValidationVariable, data_type: 'number' };
  } else if (monitorItem.value) {
    selectedData = { ...monitorItem, data_type: 'number' };
  } else if (selectedFertilizer.column_name) {
    selectedData = { ...selectedFertilizer, data_type: 'number' };
  }

  var filters = '';
  if (area1.selected) {
    filters = equalToFilter('name_1', area1.selected.value);
  }

  if (area2.selected) {
    filters = andFilter(
      equalToFilter('name_2', area2.selected.value),
      equalToFilter('name_1', area1.selected.value)
    );
  }

  if (area3.selected) {
    filters = andFilter(
      equalToFilter('name_3', area3.selected.value),
      equalToFilter('name_2', area2.selected.value),
      equalToFilter('name_1', area1.selected.value)
    );
  }
  if (area4.selected) {
    filters = andFilter(
      equalToFilter('name_4', area4.selected.value),
      equalToFilter('name_3', area3.selected.value),
      equalToFilter('name_2', area2.selected.value),
      equalToFilter('name_1', area1.selected.value)
    );
  }

  return {
    showRaster,

    selectedLevel: selectedLevel.value,
    country: state.geo.country.selected
      ? state.geo.country.selected.value
      : null,
    area1: area1.selected,
    area2: area2.selected,
    area3: area3.selected,
    area4: area4.selected,
    address,

    selectedVariable,
    styles: styles ? styles : [],
    stylesLoading: state.cap.loading,

    selectedPillar: selectedPillar,
    selectedType: selectedType,

    loading,

    selectedCrop,
    selectedLand,
    selectedWeather,
    selectedSetting,
    selectedMenu,
    selectedBankCode,
    selectedValidationVariable,

    monitor,
    monitorItem,

    selectedData,
    filters,
    selectedFertilizer,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getStyles,
        setStyles,
        setFilteredVariableData,
        setChartData,
        getWeatherDataAtPixel,
        getRainFall,
        setRainFall,
      },
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchtoProps)(MapContainer);
