import React from 'react';
import { TextInput } from '../../../../NewComponents';
import { useTranslation } from 'react-i18next';

export const PersonalInfo = ({
  personalInfo,
  handlePersonalInfoChange,
  error,
  confirmPassword,
  setConfirmEmail,
  confirmEmail,
  setConfirmPassword,
}) => {
  const { t } = useTranslation();

  return (
    <div className="personal_info">
      <TextInput
        name="email"
        placeholder={t('Email')}
        value={personalInfo.email}
        showLabel={true}
        onChange={(e) => handlePersonalInfoChange(e)}
        icon="/images/login/Group.svg"
        error={error.email ? ' ' : ''}
      />

      <TextInput
        name="confirmEmail"
        placeholder={t('Confirm Email')}
        value={confirmEmail}
        showLabel={true}
        onChange={(e) => setConfirmEmail(e.target.value)}
        icon="/images/login/Group.svg"
        error={error.email ? ' ' : ''}
      />

      <TextInput
        name="first_name"
        placeholder={t('First name')}
        value={personalInfo.first_name}
        showLabel={true}
        onChange={(e) => handlePersonalInfoChange(e)}
        icon="/images/login/Group.svg"
        error={error.first_name ? ' ' : ''}
      />

      <TextInput
        name="last_name"
        placeholder={t('Last name')}
        value={personalInfo.last_name}
        showLabel={true}
        onChange={(e) => handlePersonalInfoChange(e)}
        icon="/images/login/Group.svg"
        error={error.last_name ? ' ' : ''}
      />

      <TextInput
        name="user_password"
        placeholder={t('Password')}
        showLabel={true}
        icon="/images/login/key.svg"
        type="password"
        onChange={(e) => handlePersonalInfoChange(e)}
        value={personalInfo.user_password}
        error={error.user_password ? ' ' : ''}
      />

      <TextInput
        name="confirmPassword"
        placeholder={t('Confirm Password')}
        showLabel={true}
        icon="/images/login/key.svg"
        type="password"
        onChange={(e) => setConfirmPassword(e.target.value)}
        value={confirmPassword}
        error={error.user_password ? ' ' : ''}
      />
    </div>
  );
};
