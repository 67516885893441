import axios from "axios";

const Api = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_GEO_API_URL + "/api",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  let api = axios.create(defaultOptions);
  //set Auth token for any request

  return api;
};
export default Api();
