import React from 'react';
import Index from '../../../Theme/Views/Index';
// import Map from '../../Map/Views/Map';
import Map from '../../Map/Views/MapContainer';

import Card from '../../../Common/Card';
import Sidebar from '../../Map/Views/Sidebar/Sidebar';
import CurrentLegend from '../../Map/Views/Legend/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Home = (props) => {
  return (
    <Index>
      <Card id="card" className="map-area">
        <Sidebar></Sidebar>
        <CurrentLegend></CurrentLegend>
        <Map />
      </Card>
    </Index>
  );
};

const mapStateToProps = (state) => {
  return {};
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(Home);
