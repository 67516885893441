/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react';
import './sidebar.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  typeSelect,
  getCapVariables,
  setCapVariables,
} from '../../../Cap/action';

import {
  setSelectedCrop,
  setSelectedMenu,
  setSelectedLand,
  setSelectedWeather,
  setSelectedSetting,
  setWeatherDataAtPixel,
  setSelectedBankCode,
  setSelectedValidationVariable,
  setSelectedMonitor,
  setSelectedMonitorItem,
  setSelectedFertilizer,
} from '../../../Geo/action';
import { changeRasterVisibility } from '../../../Map/action';
import ReactTooltip from 'react-tooltip';

import {
  types,
  landscapes,
  weather,
  settings,
  cropValidationVariables,
  monitors,
  fertilizerMenu,
} from './constants';
import DropDown from '../../../../NewComponents/Dropdown/DropDownRS';

const Sidebar = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [variable, setVariable] = useState('');

  const [selectedFertilizer, setFertilizer] = useState('');

  const handleSelect = (type) => {
    // !props.selectedType && setCollapsed(!collapsed);
    setCollapsed(false);
    props.actions.typeSelect(type);
  };

  const handleBankCodeSelect = (code) => {
    props.actions.setSelectedBankCode(code);
  };

  const handleVariableClick = (variable) => {
    props.actions.setCapVariables(variable);
    setVariable(variable);
  };

  const handleSelectedMonitor = (item) => {
    props.actions.setSelectedMonitor(item);
  };

  useEffect(() => {
    setVariable('');
    props.actions.setCapVariables({});

    console.log("props.selectedType.value",props.selectedType.value);
    console.log("props.selectedPillar.label",props.selectedPillar.label);
    
    if (props.selectedType.value && props.selectedPillar.label) {
      const params = {
        pillar: props.selectedPillar.label,
        type: props.selectedType.value,
      };
      props.actions.getCapVariables(params);
    }
  }, [props.actions, props.selectedPillar, props.selectedType]);

  const handleCropSelect = (crop) => {
    props.actions.setSelectedCrop(crop);
    props.actions.changeRasterVisibility('show');
  };

  const handleLandSelect = (land) => {
    props.actions.setSelectedLand(land);
  };

  const handleWeatherSelect = (item) => {
    props.actions.setSelectedWeather(item);
    if (item.value === 'spi_mean') {
      props.actions.setWeatherDataAtPixel();
    }
  };

  const handleSelectedSetting = (item) => {
    props.actions.setSelectedSetting(item);
  };

  const tabStyle = collapsed ? 'tab closed' : 'tab open';

  // const newMonitorMenus = [...props.monitorMenus, ...monitors];
  return (
    <>
      <div className="sidebar top">
        {props.selectedPillar.label && (
          <div className={tabStyle}>
            <div className="header">
              <p className="title">{props.selectedType.label}</p>
              <div
                className={'minimize-btn'}
                onClick={() => {
                  setCollapsed(true);
                }}
              >
                <img src="/images/right_arrow.png" alt=""></img>
              </div>
            </div>
            <div className="tab-body">
              {props.variables.map((item, key) => {
                let className = 'variable';
                let condition = item.value === variable.value;
                if (condition) {
                  className = 'variable selected';
                }
                return (
                  <Fragment key={key}>
                    <div className="variable-item">
                      <div
                        className={className}
                        onClick={() => {
                          // variable.value !== item.value
                          //   ? handleVariableClick(item)
                          //   : setVariable('');
                          handleVariableClick(item);
                        }}
                      >
                        {item.label}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        )}
        {props.selectedMenu === props.selectedPillar.label && (
          <div className="items">
            {types.map((type, key) => {
              return (
                <div key={key} className="item">
                  <a
                    href="#"
                    onClick={() => handleSelect(type)}
                    data-for="sidebar"
                    data-tip={type.label}
                  >
                    <ReactTooltip id="sidebar" type="dark" effect="solid" />
                    <img
                      src={type.imgSrc}
                      height="25"
                      width="25"
                      alt={type.label}
                      style={{
                        filter:
                          type.value === props.selectedType.value
                            ? ''
                            : 'grayscale(1)',
                        transition: 'all 2s',
                      }}
                    ></img>
                  </a>
                </div>
              );
            })}
          </div>
        )}

        {props.selectedMenu === 'Crop validation' && (
          <div className="crops-validation-container">
            <div className="crop-validation">
              <DropDown
                options={props.bankCodeList}
                onChange={(item) => handleBankCodeSelect(item)}
                minWidth={'100%'}
              />
            </div>

            {props.selectedBankCode.value && (
              <div className="crop-validation-variables">
                <div className="scroll-view">
                  {cropValidationVariables.map((data, key) => {
                    let className =
                      props.selectedValidationVariable.column_name ===
                      data.column_name
                        ? 'crop-validation-variable selected'
                        : 'crop-validation-variable';
                    return (
                      <div
                        className={className}
                        onClick={() => {
                          props.actions.setSelectedValidationVariable(data);
                        }}
                        key={key}
                      >
                        <p>
                          {`${data.label}`} {data.unit ? `(${data.unit})` : ''}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}

        {props.selectedMenu === 'Crop suitability' && (
          <div className="crops-container">
            <div className="header">
              <p className="title">{props.selectedMenu}</p>
              <div
                className="close-btn"
                onClick={() => {
                  props.actions.setSelectedMenu('');
                }}
              >
                <i className="fa fa-times" />
              </div>
            </div>
            <div className="crops">
              {props.crops.map((crop, key) => {
                let className =
                  crop.id === props.selectedCrop.id ? 'crop selected' : 'crop';

                if (crop.has_raster) {
                  return (
                    <div
                      className={className}
                      onClick={() => handleCropSelect(crop)}
                      key={key}
                    >
                      {crop.name}
                    </div>
                  );
                }
                // For disabling the crop that do not have raster
                // else {
                //   return (
                //     <div className="crop-disabled" key={key}>
                //       {crop.name}
                //     </div>
                //   );
                // }
                return null;
              })}
            </div>
          </div>
        )}

        {props.selectedMenu === 'Landscape' && (
          <div className="crops-container">
            <div className="header">
              <p className="title">{props.selectedMenu}</p>
              <div
                className="close-btn"
                onClick={() => {
                  props.actions.setSelectedMenu('');
                }}
              >
                <i className="fa fa-times" />
              </div>
            </div>
            <div className="land">
              {landscapes.map((land, key) => {
                let className =
                  land.value === props.selectedLand.value
                    ? 'crop selected'
                    : 'crop';
                return (
                  <div
                    className={className}
                    key={key}
                    onClick={() => handleLandSelect(land)}
                  >
                    {land.label}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {props.selectedMenu === 'Weather' && (
          <div className="crops-container">
            <div className="header">
              <p className="title">{props.selectedMenu}</p>
              <div
                className="close-btn"
                onClick={() => {
                  props.actions.setSelectedMenu('');
                }}
              >
                <i className="fa fa-times" />
              </div>
            </div>
            <div className="land">
              {weather.map((item, key) => {
                let className =
                  item.value === props.selectedWeather.value
                    ? 'crop selected'
                    : 'crop';
                return (
                  <div
                    className={className}
                    key={key}
                    onClick={() => {
                      handleWeatherSelect(item);
                    }}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {props.selectedMenu === 'Settings' && (
          <div className="crops-container">
            <div className="header">
              <p className="title">{props.selectedMenu}</p>
              <div
                className="close-btn"
                onClick={() => {
                  props.actions.setSelectedMenu('');
                }}
              >
                <i className="fa fa-times" />
              </div>
            </div>
            <div className="land">
              {settings.map((item, key) => {
                let className =
                  item.value === props.selectedSetting.value
                    ? 'crop selected'
                    : 'crop';
                return (
                  <div
                    className={className}
                    key={key}
                    onClick={() => handleSelectedSetting(item)}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {props.selectedMenu === 'Activity monitor' && (
          <div className="crops-validation-container">
            <div className="crop-validation">
              <DropDown
                // options={newMonitorMenus}
                options={monitors}
                onChange={(item) => handleSelectedMonitor(item)}
                minWidth={'100%'}
              />
            </div>
            {props.monitor.value && (
              <div className="crop-validation-variables">
                <div className="scroll-view">
                  {props.monitor.monitorItems.map((data, key) => {
                    let className =
                      props.monitorItem.value === data.value
                        ? 'crop-validation-variable selected'
                        : 'crop-validation-variable';
                    return (
                      <div
                        className={className}
                        onClick={() => {
                          props.actions.setSelectedMonitorItem(data);
                        }}
                        key={key}
                      >
                        <p>{`${data.label}`}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}

        {props.selectedMenu === 'Fertilizer orders' && (
          <div className="crops-validation-container">
            <div className="crop-validation">
              <DropDown
                options={props.fertilizers}
                onChange={(item) => {
                  setFertilizer(item.value);
                  props.actions.setSelectedFertilizer({});
                }}
                minWidth={'100%'}
                placeholder="Select fertilizer"
              />
            </div>

            {/* <div className="header">
              <p className="title">{props.selectedMenu}</p>
              <div
                className="close-btn"
                onClick={() => {
                  props.actions.setSelectedMenu('');
                }}
              >
                <i className="fa fa-times" />
              </div>
            </div> */}
            {selectedFertilizer && (
              <div className="crop-validation-variables">
                <div className="scroll-view">
                  {fertilizerMenu.map((data, key) => {
                    let className =
                      props.selectedFertilizer.label === data.label
                        ? 'crop-validation-variable selected'
                        : 'crop-validation-variable';
                    return (
                      <div
                        className={className}
                        onClick={() => {
                          props.actions.setSelectedFertilizer({
                            ...data,
                            column_name: data.prefix + selectedFertilizer,
                          });
                        }}
                        key={key}
                      >
                        <p>{`${data.label}`}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* <div className="land">
              {fertilizerMenu.map((item, key) => {
                let className = 'crop';

                if (item.column_name === props.selectedFertilizer.column_name) {
                  className = 'crop selected';
                }
                return (
                  <div
                    className={className}
                    key={key}
                    onClick={() => {
                      props.actions.setSelectedFertilizer(item);
                    }}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div> */}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { cap, geo } = state;
  const {
    crops,
    fertilizers,
    selectedCrop,
    selectedMenu,
    selectedLand,
    selectedWeather,
    selectedSetting,

    bankCodeList,
    selectedBankCode,
    selectedValidationVariable,
    monitor,
    monitorItem,
    selectedFertilizer,
    // monitorMenus,
  } = geo;
  const { selectedType, selectedPillar, variables, styles } = cap;

  return {
    selectedType,
    selectedPillar,
    variables,
    styles,

    crops,
    fertilizers,
    selectedCrop,
    selectedMenu,
    selectedLand,
    selectedWeather,
    selectedSetting,

    bankCodeList,
    selectedBankCode,
    selectedValidationVariable,
    monitor,
    monitorItem,
    selectedFertilizer,

    // monitorMenus,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        typeSelect,
        getCapVariables,
        setCapVariables,
        setSelectedCrop,
        changeRasterVisibility,
        setSelectedMenu,
        setSelectedLand,
        setSelectedWeather,
        setSelectedSetting,
        setWeatherDataAtPixel,
        setSelectedBankCode,
        setSelectedValidationVariable,

        setSelectedMonitor,
        setSelectedMonitorItem,
        setSelectedFertilizer,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(Sidebar);
