import GisActionTypes from "./actionTypes";
const INITIAL_STATE = {
  country: null,
  provinces: {},
  communes: {},
  zones: {},
  collines: {},
  localities: {},
  groups: {},
  loading: false,
  capData: [],
  message: "",
  error: { code: "", message: "", field: "" }
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GisActionTypes.GIS_COUNTRY_REQUEST:
      return { ...state, loading: true };
    case GisActionTypes.GIS_COUNTRY_SUCCESS:
      return { ...state, country: action.country, loading: false };
    case GisActionTypes.GIS_PROVINCES_REQUEST:
      return { ...state, loading: true };
    case GisActionTypes.GIS_PROVINCES_SUCCESS:
      return { ...state, provinces: action.list, loading: false };
    case GisActionTypes.GIS_COMMUNES_REQUEST:
      return { ...state, loading: true };
    case GisActionTypes.GIS_COMMUNES_SUCCESS:
      return { ...state, communes: action.list, loading: false };
    case GisActionTypes.GIS_ZONES_REQUEST:
      return { ...state, loading: true };
    case GisActionTypes.GIS_ZONES_SUCCESS:
      return { ...state, zones: action.list, loading: false };

    case GisActionTypes.GIS_COLLINES_REQUEST:
      return { ...state, loading: true };
    case GisActionTypes.GIS_COLLINES_SUCCESS:
      return { ...state, collines: action.list, loading: false };

    case GisActionTypes.GIS_LOCALITIES_REQUEST:
      return { ...state, loading: true };
    case GisActionTypes.GIS_LOCALITIES_SUCCESS:
      return { ...state, localities: action.list, loading: false };

    case GisActionTypes.GIS_GROUPS_REQUEST:
      return { ...state, loading: true };
    case GisActionTypes.GIS_GROUPS_SUCCESS:
      return { ...state, groups: action.list, loading: false };

    case GisActionTypes.GIS_CAP_DATA_REQUEST:
      return { ...state, loading: true };
    case GisActionTypes.GIS_CAP_DATA_SUCCESS:
      let data = [...state.capData];
      let filtered = data.filter(row => {
        return row.name === action.data.name;
      });
      console.log("filtered", filtered);
      if (filtered.length <= 0) {
        console.log("notfiltered");
        data.push(action.data);
      }
      //let capdata=[...state.capData];
      return { ...state, capData: data, loading: false };
    default:
      return state;
  }
}
