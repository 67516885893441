import MapActionTypes from "./actionTypes";

export function setSelectLayer(params) {
  return {
    type: MapActionTypes.MAP_LAYER_CLICK,
    loading: true,
    data: params,
  };
}

export function setLevel(params) {
  return {
    type: MapActionTypes.SELECT_MAP_LEVEL,
    data: params,
  };
}

export function changeLevels(params) {
  return {
    type: MapActionTypes.CHANGE_LEVELS,
    params,
  };
}

export function changeRasterVisibility(data) {
  return {
    type: MapActionTypes.CHANGE_RASTER_VISIBILITY,
    data,
  };
}

export function setSelectedStat(data) {
  return {
    type: MapActionTypes.SET_SELECTED_STAT,
    data,
  };
}

export function clearAllMapState() {
  return {
    type: MapActionTypes.CLEAR_ALL,
  };
}
