import React from "react";
import PropTypes from "prop-types";
const Card = (props) => {
  return (
    <div className={`card ${props.className}`} style={props.style}>
      {props.image && (
        <div className={`card-header ${props.headerClass}`}>
          <img
            src={props.image}
            style={{ width: "50%", height: "50%", objectFit: "contain" }}
            alt=""
          />
        </div>
      )}
      <div className="card-body">
        {props.title && <h2>{props.title}</h2>}
        {props.children}
      </div>
    </div>
  );
};
Card.propTypes = {
  image: PropTypes.any,
  className: PropTypes.string,
  headerClass: PropTypes.string,
};
Card.defaultProps = {
  className: "",
};
export default Card;
