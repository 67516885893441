import MapActionTypes from './actionTypes';
const INITIAL_STATE = {
  selectedData: {},
  message: '',
  error: { code: '', message: '', field: '' },
  selectedLevel: '',
  levels: [
    { value: '1', label: 'Level 1' },
    { value: '2', label: 'Level 2' },
    { value: '3', label: 'Level 3' },
    { value: '4', label: 'Level 4' },
  ],

  showRaster: false,
  loading: false,

  selectedStat: 'table',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case MapActionTypes.MAP_LAYER_CLICK:
      return { ...state, selectedData: action.data, loading: false };

    case MapActionTypes.SELECT_MAP_LEVEL:
      return { ...state, selectedLevel: action.data };

    case MapActionTypes.CHANGE_LEVELS:
      return { ...state, levels: action.params };

    case MapActionTypes.CHANGE_RASTER_VISIBILITY:
      let showRaster;
      if (action.data === 'hide') {
        showRaster = false;
      } else if (action.data === 'show') {
        showRaster = true;
      } else {
        showRaster = !state.showRaster;
      }
      return {
        ...state,
        showRaster,
      };

    case MapActionTypes.SET_SELECTED_STAT:
      return {
        ...state,
        selectedStat: action.data,
      };

    case MapActionTypes.CLEAR_ALL:
      return {
        ...state,
        showRaster: false,
        loading: false,

        selectedStat: 'table',
      };

    default:
      return state;
  }
}
