import GeoActionTypes from './actionTypes';
import { take, call, put, fork } from 'redux-saga/effects';
import { GeoService } from './Service';

export function* getCountry() {
  while (true) {
    yield take(GeoActionTypes.GEO_COUNTRY_SELECT_REQUEST);
    const { data, error } = yield call(GeoService.getCountry);
    if (!error) {
      yield put({
        type: GeoActionTypes.GEO_COUNTRY_SELECT_SUCCESS,
        country: data.result,
      });
    }
  }
}

export function* getProvinces() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GEO_PROVINCE_LIST_REQUEST);
    const { data, error } = yield call(GeoService.getProvinces, params);
    if (!error) {
      yield put({
        type: GeoActionTypes.GEO_PROVINCE_LIST_SUCCESS,
        list: data.result,
      });
    }
  }
}
export function* getCommunes() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GEO_COMMUNE_LIST_REQUEST);
    const { data, error } = yield call(GeoService.getCommunes, params);
    if (!error) {
      yield put({
        type: GeoActionTypes.GEO_COMMUNE_LIST_SUCCESS,
        list: data.result,
      });
    }
  }
}
export function* getZones() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GEO_ZONE_LIST_REQUEST);
    const { data, error } = yield call(GeoService.getZones, params);
    if (!error) {
      yield put({
        type: GeoActionTypes.GEO_ZONE_LIST_SUCCESS,
        list: data.result,
      });
    }
  }
}

export function* getCollines() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GEO_COLLINE_LIST_REQUEST);
    const { data, error } = yield call(GeoService.getCollines, params);
    if (!error) {
      yield put({
        type: GeoActionTypes.GEO_COLLINE_LIST_SUCCESS,
        list: data.result,
      });
    }
  }
}
export function* getLocalities() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GEO_LOCALITY_LIST_REQUEST);
    const { data, error } = yield call(GeoService.getLocalities, params);
    if (!error) {
      yield put({
        type: GeoActionTypes.GEO_LOCALITY_LIST_SUCCESS,
        list: data.result,
      });
    }
  }
}
export function* getGroups() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GEO_GROUP_LIST_REQUEST);
    const { data, error } = yield call(GeoService.getGroups, params);
    if (!error) {
      yield put({
        type: GeoActionTypes.GEO_GROUP_LIST_SUCCESS,
        list: data.result,
      });
    }
  }
}

export function* getArea1() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GET_AREA1_REQUEST);
    const { data, error } = yield call(GeoService.getArea1, params);
    if (!error) {
      let area1 = data.result.area1;
      let sortedArea1 = [];

      area1.map((item) => {
        if (!item.isDisabled) {
          sortedArea1.push(item);
        }
        return null;
      });
      area1.map((item) => {
        if (item.isDisabled) {
          sortedArea1.push(item);
        }
        return null;
      });

      yield put({
        type: GeoActionTypes.GET_AREA1_SUCCESS,
        address: data.result.address,
        area1: sortedArea1,
      });
    }
  }
}

export function* getArea2() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GET_AREA2_REQUEST);
    const { data, error } = yield call(GeoService.getArea2, params);
    if (!error) {
      yield put({
        type: GeoActionTypes.GET_AREA2_SUCCESS,
        area2: data.result,
      });
    }
  }
}

export function* getArea3() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GET_AREA3_REQUEST);
    const { data, error } = yield call(GeoService.getArea3, params);
    if (!error) {
      yield put({
        type: GeoActionTypes.GET_AREA3_SUCCESS,
        area3: data.result,
      });
    }
  }
}
export function* getArea4() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GET_AREA4_REQUEST);
    const { data, error } = yield call(GeoService.getArea4, params);
    if (!error) {
      yield put({
        type: GeoActionTypes.GET_AREA4_SUCCESS,
        area4: data.result,
      });
    }
  }
}

export function* getCrops() {
  while (true) {
    yield take(GeoActionTypes.GET_CROP_REQUEST);
    const { data, error } = yield call(GeoService.getCrops);

    if (!error) {
      yield put({
        type: GeoActionTypes.GET_CROP_SUCCESS,
        data,
      });
    } else {
      yield put({ type: GeoActionTypes.GET_CROP_FAIL, error });
    }
  }
}

export function* getActivityMonitorMenus() {
  while (true) {
    yield take(GeoActionTypes.GET_AGRICOACH_ACTIVITY_MENUS_REQ);
    const { data, error } = yield call(GeoService.getActivityMonitorMenus);

    if (!error) {
      yield put({
        type: GeoActionTypes.GET_AGRICOACH_ACTIVITY_MENUS_SUC,
        data,
      });
    } else {
      yield put({
        type: GeoActionTypes.GET_AGRICOACH_ACTIVITY_MENUS_FAIL,
        error,
      });
    }
  }
}

export function* getCropValidationBankList() {
  while (true) {
    yield take(GeoActionTypes.GET_CROP_VALIDATION_LIST_REQ);
    const { data, error } = yield call(GeoService.getCropValidationBankList);

    if (!error) {
      yield put({
        type: GeoActionTypes.GET_CROP_VALIDATION_LIST_SUC,
        data,
      });
    } else {
      yield put({ type: GeoActionTypes.GET_CROP_VALIDATION_LIST_FAIL, error });
    }
  }
}

export function* getRainData() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GET_RAIN_FALL_DATA_REQ);

    const { data, error } = yield call(GeoService.getRainData, params);
    if (!error) {
      yield put({
        type: GeoActionTypes.GET_RAIN_FALL_DATA_SUC,
        data: { ...data, ...params },
      });
    } else {
      yield put({ type: GeoActionTypes.GET_CROP_FAIL, error });
    }
  }
}

export function* getWeatherDataAtPixel() {
  while (true) {
    const { params } = yield take(GeoActionTypes.GET_WEATHER_DATA_REQ);
    const { data, error } = yield call(
      GeoService.getWeatherDataAtPixel,
      params
    );
    if (!error) {
      const spi_max = yield call(GeoService.getWeatherDataAtPixel, {
        ...params,
        layer: 'spi_max',
      });

      const spi_min = yield call(GeoService.getWeatherDataAtPixel, {
        ...params,
        layer: 'spi_min',
      });
      yield put({
        type: GeoActionTypes.GET_WEATHER_DATA_SUC,
        data,
        spi_max: spi_max.data,
        spi_min: spi_min.data,
        params,
      });
    } else {
      yield put({ type: GeoActionTypes.GET_CROP_FAIL, error });
    }
  }
}

export function* getFertilizers() {
  while (true) {
    yield take(GeoActionTypes.GET_FERTILIZERS_REQUEST);
    const { data, error } = yield call(GeoService.getFertilizers);

    if (!error) {
      yield put({
        type: GeoActionTypes.GET_FERTILIZERS_SUCCESS,
        data,
      });
    } else {
      yield put({ type: GeoActionTypes.GET_FERTILIZERS_FAIL, error });
    }
  }
}

export default function* GeoSaga() {
  yield fork(getCountry);
  yield fork(getProvinces);
  yield fork(getCommunes);
  yield fork(getZones);
  yield fork(getCollines);
  yield fork(getLocalities);
  yield fork(getGroups);
  yield fork(getArea1);
  yield fork(getArea2);
  yield fork(getArea3);
  yield fork(getArea4);
  yield fork(getCrops);
  yield fork(getWeatherDataAtPixel);
  yield fork(getCropValidationBankList);
  yield fork(getRainData);
  yield fork(getFertilizers);
  yield fork(getActivityMonitorMenus);
}
