const GeoActionTypes = {
  GEO_COUNTRY_SELECT_REQUEST: 'geo.country.select.request',
  GEO_COUNTRY_SELECT_SUCCESS: 'geo.country.select.success',
  GEO_COUNTRY_SELECTED: 'geo.country.selected',
  GEO_PROVINCE_LIST_REQUEST: 'geo.province.list.request',
  GEO_PROVINCE_LIST_SUCCESS: 'geo.province.list.success',
  GEO_PROVINCE_SELECTED: 'geo.province.selected',
  GEO_COMMUNE_LIST_REQUEST: 'geo.commune.list.request',
  GEO_COMMUNE_LIST_SUCCESS: 'geo.commune.list.success',
  GEO_COMMUNE_SELECTED: 'geo.commune.selected',
  GEO_ZONE_LIST_REQUEST: 'geo.zone.list.request',
  GEO_ZONE_LIST_SUCCESS: 'geo.zone.list.success',
  GEO_ZONE_SELECTED: 'geo.zone.selected',
  GEO_COLLINE_LIST_REQUEST: 'geo.colline.list.request',
  GEO_COLLINE_LIST_SUCCESS: 'geo.colline.list.success',
  GEO_COLLINE_SELECTED: 'geo.colline.selected',
  GEO_LOCALITY_LIST_REQUEST: 'geo.locality.list.request',
  GEO_LOCALITY_LIST_SUCCESS: 'geo.locality.list.success',
  GEO_LOCALITY_SELECTED: 'geo.locality.selected',
  GEO_GROUP_LIST_REQUEST: 'geo.group.list.request',
  GEO_GROUP_LIST_SUCCESS: 'geo.group.list.success',
  GEO_GROUP_SELECTED: 'geo.group.selected',

  GET_AREA1_REQUEST: 'get.area1.request',
  GET_AREA1_SUCCESS: 'get.area1.success',

  SET_AREA1_SELECTED: 'set.area1.selected',

  GET_AREA2_REQUEST: 'get.area2.request',
  GET_AREA2_SUCCESS: 'get.area2.success',

  SET_AREA2_SELECTED: 'set.area2.selected',

  GET_AREA3_REQUEST: 'get.area3.request',
  GET_AREA3_SUCCESS: 'get.area3.success',

  SET_AREA3_SELECTED: 'set.area3.selected',

  GET_AREA4_REQUEST: 'get.area4.request',
  GET_AREA4_SUCCESS: 'get.area4.success',

  SET_AREA4_SELECTED: 'set.area4.selected',

  GET_CROP_REQUEST: 'get.crop.request',
  GET_CROP_SUCCESS: 'get.crop.success',
  GET_CROP_FAIL: 'get.crop.fail',

  SET_CROPS: 'set.crops',

  SET_SELECTED_CROP: 'set.selected.crop',

  GET_CROP_VALIDATION_LIST_REQ: 'get.crop.validation.list.request',
  GET_CROP_VALIDATION_LIST_SUC: 'get.crop.validation.list.success',
  GET_CROP_VALIDATION_LIST_FAIL: 'get.crop.validation.list.fail',

  SET_CROP_VALIDATION_LIST_REQ: 'set.crop.validation.list.request',
  SET_SELECTED_BANK_CODE: 'set.selected.bank.code',
  SET_SELECTED_VALIDATION_VARIABLE: 'set.selected.validation.variable',

  SET_SELECTED_MENU: 'set.selected.menu',
  SET_SELECTED_LAND: 'set.selected.land',

  SET_SELECTED_WEATHER: 'set.selected.weather',

  SET_SELECTED_SETTING: 'set.selected.setting',

  GET_WEATHER_DATA_REQ: 'get.weather.data.req',
  GET_WEATHER_DATA_SUC: 'get.weather.data.suc',

  SET_WEATHER_DATA: 'set.weather.data',

  SET_ADDRESS: 'set.address',

  GET_RAIN_FALL_DATA_SUC: 'get_rain_fall_data_suc',
  GET_RAIN_FALL_DATA_REQ: 'get_rain_fall_data_req',
  SET_RAIN_FALL_DATA: 'set_rain_fall_data',

  SET_SELECTED_MONITOR: 'set.selected.monitor',
  SET_SELECTED_MONITOR_ITEM: 'set.selected.monitor.item',

  SET_SELECTED_FERTILIZER: 'set_selected_fertilizer',

  CLEAR_ALL: 'clear.all',

  GET_FERTILIZERS_REQUEST: 'get_fertilizer_request',
  GET_FERTILIZERS_SUCCESS: 'get_fertilizer_success',
  GET_FERTILIZERS_FAIL: 'get_fertilizer_fail',

  GET_AGRICOACH_ACTIVITY_MENUS_REQ: 'get_agricoach_activity_menus_request',
  GET_AGRICOACH_ACTIVITY_MENUS_SUC: 'get_agricoach_activity_menus_success',
  GET_AGRICOACH_ACTIVITY_MENUS_FAIL: 'get_agricoach_activity_menu_fail',
};
export default GeoActionTypes;
