const GisActionTypes={
    GIS_COUNTRY_REQUEST:'gis.country.request',
    GIS_COUNTRY_SUCCESS:'gis.country.success',
    GIS_COUNTRY_FAIL:'gis.country.fail',
    GIS_PROVINCES_REQUEST:'gis.provinces.request',
    GIS_PROVINCES_SUCCESS:'gis.provinces.success',
    GIS_PROVINCES_FAIL:'gis.provinces.fail',
    GIS_COMMUNES_REQUEST:'gis.communes.request',
    GIS_COMMUNES_SUCCESS:'gis.communes.success',
    GIS_COMMUNES_FAIL:'gis.communes.fail',
    GIS_ZONES_REQUEST:'gis.zones.request',
    GIS_ZONES_SUCCESS:'gis.zones.success',
    GIS_ZONES_FAIL:'gis.zones.fail',
    GIS_COLLINES_REQUEST:'gis.collines.request',
    GIS_COLLINES_SUCCESS:'gis.collines.success',
    GIS_COLLINES_FAIL:'gis.collines.fail',
    GIS_LOCALITIES_REQUEST:'gis.localities.request',
    GIS_LOCALITIES_SUCCESS:'gis.localities.success',
    GIS_LOCALITIES_FAIL:'gis.localities.fail',
    GIS_GROUPS_REQUEST:'gis.groups.request',
    GIS_GROUPS_SUCCESS:'gis.groups.success',
    GIS_GROUPS_FAIL:'gis.groups.fail',
    GIS_CAP_DATA_REQUEST:'gis.cap_data_request',
    GIS_CAP_DATA_SUCCESS:'gis.cap_data_success'
}
export default GisActionTypes;