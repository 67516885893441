import geostats from 'geostats';
import {
  colors,
  colorsQualitative,
  weatherColors,
  // weather,
} from './Sidebar/constants';
import 'chartjs-plugin-annotation';

export function roundUpDecimals(num) {
  if (num) {
    if (Number.isInteger(num)) {
      return num.toLocaleString();
    } else {
      return num.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      // return Math.round(num * 100) / 100;
    }
  }
  return num;
}

export const generateWeatherPopup = (weatherFeature, area, selectedLevel) => {
  const value = weatherFeature.get('value')
    ? roundUpDecimals(weatherFeature.get('value')) + ' mm'
    : 'Not reported';
  const x_coord = weatherFeature.get('x_coord');
  const y_coord = weatherFeature.get('y_coord');

  let province = weatherFeature.get('province');
  let commune = weatherFeature.get('commune');
  let zone = weatherFeature.get('zone');
  let colline = weatherFeature.get('colline');

  let names = [0, province, commune, zone, colline];

  const currentAddress =
    selectedLevel == area + 1
      ? names[selectedLevel]
      : names[selectedLevel - 1] + ' | ' + names[selectedLevel];
  let displayHtml = `
  <div>
    <p>${currentAddress}</p>
  </div>
  <div>
    <p>Rainfall : ${value}</p>
  </div>
  <div>
    <p>Location : ${x_coord}, ${y_coord}</p>
  </div>
  `;
  return displayHtml;
};

export const generatePopupHtml = (feature, selectedData, level, area) => {
  let variable = feature.get(selectedData.column_name);
  let name_1 = feature.get('name_1');
  let name_2 = feature.get('name_2');
  let name_3 = feature.get('name_3');
  let name_4 = feature.get('name_4');

  let names = [0, name_1, name_2, name_3, name_4];
  // GITEGA | GITEGA RURAL
  let currentAddress =
    level == area + 1 ? names[level] : names[level - 1] + ' | ' + names[level];

  let displayHtml = '';
  displayHtml += `<div><p>${currentAddress}</p></div>`;
  if (!selectedData.label) {
    return displayHtml;
  }

  let data = '';
  if (variable === null || variable === 0) {
    data = 'Not Reported';
  } else {
    data = roundUpDecimals(variable);
  }

  if (selectedData.column_name) {
    displayHtml += `<div>
                      <p> ${data} </p>
                    <div>`;
    return displayHtml;
  }

  return displayHtml;
};

export const getWeatherStyle = (feature) => {
  var value = feature.get('rain_value');

  if (value === 0) {
    return '#f7fbff';
  } else if (value === 1) {
    return '#c8ddf0';
  } else if (value === 2) {
    return '#73b3d8';
  } else if (value === 3) {
    return '#2879b9';
  } else {
    return '#08306b';
  }
};

export const generateStyles = (values, type, isWeatherStyle) => {
  let legends = [];
  let newColors = isWeatherStyle ? weatherColors : colors;

  if (type === 'number' || type === 'average') {
    let roundedValues = [];
    values.map((item) => {
      if (item !== null && item !== '') {
        roundedValues.push(item);
      }
      return null;
    });
    let data = new geostats(roundedValues);
    if (values.length > 0) {
      let uniqueItems = [...new Set(roundedValues)];

      uniqueItems.sort((a, b) => a - b);
      if (uniqueItems.length <= 5) {
        if (uniqueItems.length === 1) {
          return (legends = [
            {
              legend: uniqueItems[0],
              color: newColors[4],
              value: uniqueItems[0],
            },
          ]);
        }
        uniqueItems.map((item, key) => {
          return (legends[key] = {
            legend: item,
            color: newColors[key],
            value: item,
          });
        });
      } else {
        let noOfClasses = 5;
        const jenks = data.getClassJenks2(noOfClasses);
        jenks.map((item, key, main) => {
          if (key < main.length - 1) {
            const nextItem = main[key + 1];
            return (legends[key] = {
              legend: `${roundUpDecimals(item)} - ${roundUpDecimals(nextItem)}`,
              color: newColors[key],
              value: nextItem,
            });
          }
          return null;
        });
      }
    } else {
      return [{ legend: null, color: newColors[0], value: null }];
    }
  } else if (type === 'dropdown') {
    let unique = [...new Set(values)];
    unique.sort((a, b) => a - b);

    unique.map((item, key) => {
      return (legends[key] = {
        color: colorsQualitative[key],
        legend: item,
      });
    });
  } else {
    if (!values.length) {
      return [{ legend: null, color: newColors[0], value: null }];
    }
    let roundedValues = [];
    values.map((item) => {
      roundedValues.push(item);
      return null;
    });

    legends = [
      { legend: '0-20', color: newColors[1], value: 20 },
      { legend: '20-40', color: newColors[2], value: 40 },
      { legend: '40-80', color: newColors[3], value: 80 },
      { legend: '80-100', color: newColors[4], value: 100 },
      // { legend: "No Data", color: "#bdbdbd", value: null },
    ];

    return legends;
  }

  return legends;
};

export const generateProjectStyles = () => {
  const legend = [
    { legend: 'Reported', color: colors[4], value: 'Reported' },
    { legend: 'Not Reported', color: '#bdbdbd', value: 'UnPresent' },
  ];
  return legend;
};

export const getBooleanHeader = (text, type) => {
  return `% of G50 groups that indicated '${text}' as an ${type}`;
};
export const getBooleanSdfHeader = (text) => {
  return `${text} (% of positive responses)`;
};

export function generateChartData(
  selectedData,
  allFeatures,
  selectedType,
  actions
) {
  let data = [];
  let parents = [];
  let childs = [];
  let temp2 = [];
  let chartData = { labels: [], data: [] };

  const { label, data_type, column_name, showTotal, unit } = selectedData;

  if (allFeatures.length > 0 && column_name) {
    allFeatures.map((feature) => {
      const value = feature.properties[column_name];

      const { name_1, name_2, name_3, name_4, group } = feature.properties;

      let parent,
        child = '';

      if (name_4 && group) {
        parent = null;
        child = group;
      } else if (name_4) {
        parent = name_3;
        child = name_4;
      } else if (name_3) {
        parent = name_2;
        child = name_3;
      } else if (name_2) {
        parent = name_1;
        child = name_2;
      } else {
        parent = null;
        child = name_1;
      }

      if (value && value !== 'Not Reported') {
        if (parents.indexOf(parent) === -1) {
          parents.push(parent);
          let currentIndex = parents.indexOf(parent);
          childs.push(child);
          temp2[currentIndex] = {
            parent,
            child: [
              {
                address: child,
                text: label,
                value,
              },
            ],
          };
        } else {
          let currentIndex = parents.indexOf(parent);

          let data = temp2[currentIndex].child;

          if (childs.indexOf(child) === -1) {
            childs.push(child);
            if (value) {
              data.push({
                address: child,
                text: label,
                value,
              });
            }
            temp2[currentIndex] = {
              ...temp2[currentIndex],
              child: data,
            };
          }
        }
      }
      // chartData['text'] = label;
      if (
        data_type === 'dropdown' ||
        selectedType.value === 'Project and activities'
      ) {
        chartData['type'] = 'pie';
      } else {
        chartData['type'] = 'bar';
      }

      if (value) {
        chartData['unit'] = unit;
        let index = childs.indexOf(child);
        if (index !== -1) {
          chartData.labels[index] = child;
          chartData.data[index] = value;
        }
      }
      return null;
    });

    if (showTotal) {
      if (showTotal === 'sum') {
        const total = roundUpDecimals(
          chartData.data.reduce((a, b) => {
            return a + b;
          }, 0)
        );
        chartData['subLabel'] = `Total ${total}`;
      } else {
        const total = roundUpDecimals(getAverage(chartData.data));
        chartData['subLabel'] = `Average ${total}`;
      }
      // chartData['text'] = `${label} ( ${total} )`;
      chartData['text'] = label;
    } else {
      chartData['text'] = label;
    }

    actions.setFilteredVariableData(temp2);
  }

  let temp_data = {};
  if (chartData.type === 'pie') {
    let unique = chartData.data.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    chartData.data.map((data) => {
      unique.map((item) => {
        if (item === data) {
          temp_data[item] = temp_data[item] ? temp_data[item] + 1 : 1;
        }
        return null;
      });
      return null;
    });

    chartData['labels'] = Object.keys(temp_data);
    chartData['data'] = Object.values(temp_data);
  }
  data.sort((a, b) => {
    return b['value'] - a['value'];
  });

  actions.setChartData(chartData);
}

export function addDays(days, date) {
  var result = date ? new Date(date) : new Date();
  result.setDate(result.getDate() + days);
  return result;
}

export function getAverage(arr) {
  if (arr.length === 0) {
    return 0;
  }
  var total = 0;
  for (var i = 0; i < arr.length; i++) {
    total += arr[i];
  }
  var avg = total / arr.length;
  return avg;
}
