import React from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import 'chartjs-plugin-annotation';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chart.js/auto';

Chart.register(annotationPlugin);

const alphValue = 0.8;
const colors = {
  hex: [
    '#de2d26',
    '#fc9272',
    '#fee0d2',

    '#f7f7f7',

    '#deebf7',
    '#9ecae1',
    '#3182bd',
  ],
  rgba: [
    `rgba(222,45,38, ${alphValue})`,
    `rgba(252,146,114, ${alphValue})`,
    `rgba(254,224,210, ${alphValue})`,

    `rgba(247,247,247, ${alphValue})`,

    `rgba(222,235,247, ${alphValue})`,
    `rgba(158,202,225, ${alphValue})`,
    `rgba(49,130,189, ${alphValue})`,
  ],
};
const backgroundColors = colors.rgba;

export const generateChartData = (data, spi_max, spi_min) => {
  let labels = [];
  let values = [];
  let dottedValues = [];
  let max_values = [];
  let min_values = [];
  let max = 0;
  let min = 0;

  data.map((item, key) => {
    if (item.value > max) {
      max = item.value;
    }
    if (spi_max[key].value > max) {
      max = spi_max[key].value;
    }
    if (item.value < min) {
      min = item.value;
    }
    if (spi_min[key].value < min) {
      min = spi_min[key].value;
    }
    labels.push(item.date);
    // max_values[key] = spi_max[key].value;
    // min_values[key] = spi_min[key].value;
    if (item.band <= 276) {
      if (item.band > 270) {
        max_values[key] = spi_max[key].value;
        min_values[key] = spi_min[key].value;
      } else {
        max_values[key] = null;
        min_values[key] = null;
      }
      values.push(item.value);
      dottedValues.push(null);
    } else {
      dottedValues.push(item.value);
      max_values[key] = spi_max[key].value;
      min_values[key] = spi_min[key].value;
    }
    return null;
  });

  let commonOptions = {
    fill: false,
    // backgroundColor: "rgba(75,192,192,0.4)",
    borderColor: 'black',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointRadius: 1,
    pointHitRadius: 5,
    pointHoverBorderWidth: 2,
    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
  };
  let state = {
    labels,

    datasets: [
      {
        ...commonOptions,
        data: [...values],
        // backgroundColor: chartColors,
        label: 'Historical data',
      },
      {
        ...commonOptions,
        data: [...dottedValues, '', -3, 3],
        // backgroundColor: chartColors,
        label: 'Forecasted Data',
        borderDash: [20, 5],
        pointRadius: 0,
      },

      {
        ...commonOptions,
        label: 'Uncertainity',
        data: [...max_values, '', -3, 3],
        borderColor: 'grey',
        fill: 1,
        backgroundColor: `rgba(191, 191, 191, 0.1)`,
        pointHitRadius: -1,
      },

      {
        ...commonOptions,
        label: 'Uncertainity',
        data: [...min_values, '', -3, 3],
        borderColor: 'grey',
        fill: '-1',
        backgroundColor: `rgba(191, 191, 191, 0.1)`,
        pointHitRadius: -1,
      },
    ],
  };
  const uniqueLegends = [];
  const legends = [];
  state.datasets.map((item, key) => {
    console.log({ uniqueLegends });
    if (!uniqueLegends.includes(item.label)) {
      uniqueLegends.push(item.label);
      legends[key] = {
        text: item.label,
        fillStyle: item.fill ? item.backgroundColor : 'white',
        index: 1,
        lineWidth: 2,
        lineDash: item.borderDash ? item.borderDash : [],
        strokeStyle: item.borderColor,
      };
    }
    return null;
  });

  let annotationSetting = {
    type: 'box',
    display: true,
    // Should be one of: afterDraw, afterDatasetsDraw, beforeDraw, beforeDatasetsDraw
    drawTime: 'beforeDatasetsDraw',
    xScaleID: 'x',
    yScaleID: 'y',
    // borderColor: "yellow",
    // Stroke width
    borderWidth: 0,
  };

  const annotations = {
    box1: {
      ...annotationSetting,
      yMax: -3,
      yMin: Math.floor(min),
      backgroundColor: backgroundColors[0],
    },
    box2: {
      ...annotationSetting,
      yMax: -2,
      yMin: -3,
      backgroundColor: backgroundColors[1],
    },
    box3: {
      ...annotationSetting,
      yMax: -1,
      yMin: -2,
      backgroundColor: backgroundColors[2],
    },
    box4: {
      ...annotationSetting,
      yMax: 0,
      yMin: -1,
      backgroundColor: backgroundColors[3],
    },
    box5: {
      ...annotationSetting,
      yMax: 1,
      yMin: 0,
      backgroundColor: backgroundColors[3],
    },
    box6: {
      ...annotationSetting,
      yMax: 2,
      yMin: 1,
      backgroundColor: backgroundColors[4],
    },
    box7: {
      ...annotationSetting,
      yMax: 3,
      yMin: 2,
      backgroundColor: backgroundColors[5],
    },
    box8: {
      ...annotationSetting,
      yMax: Math.ceil(max),
      yMin: 3,
      backgroundColor:
        Math.ceil(max) > 3 ? backgroundColors[6] : backgroundColors[5],
    },
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Drought/wetness index',
        color: '#ff6600',
        font: {
          size: 14,
        },
      },
      legend: {
        labels: {
          generateLabels: () => {
            return legends.map((item) => {
              return item;
            });
          },
        },
      },
      annotation: {
        annotations,
      },
    },
    scales: {
      x: {
        ticks: {
          callback: (value, index) => {
            const mainData = labels[value];
            if (index === 0) {
              return moment(mainData).format('YYYY MMM');
            }
            const month = moment(mainData).format('MMM');
            const prevMonth = moment(labels[index - 1]).format('MMM');
            const condition = month !== prevMonth;
            if (condition) {
              return month;
            } else {
              return null;
            }
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'SPI 3 Months',
          color: '#ff6600',
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return { state, options };
};

export const ModalChart = ({
  weatherDataAtPixel,
  weatherDataParams,
  spi_max,
  spi_min,
  classname,
  setWeatherDataAtPixel,
}) => {
  const { state, options } = generateChartData(
    weatherDataAtPixel,
    spi_max,
    spi_min
  );

  const { x_coord, y_coord } = weatherDataParams;
  return (
    <div className={classname + ' spi'}>
      <div className="subtitle">{`( ${x_coord}, ${y_coord} )`}</div>

      <div
        className="close-btn"
        onClick={() => setWeatherDataAtPixel()}
        style={{ top: '5px' }}
      >
        <i className="fa fa-times" />
      </div>
      <Line
        data={state}
        options={options}
        datasetIdKey={() => {
          return Math.random();
        }}
      />
    </div>
  );
};
