import { createStore, applyMiddleware, compose } from "redux";
// import Logger from "redux-logger";
import reducers from "./reducers";
import createSagaMiddleware from "redux-saga";
import sagas from "./saga";
export default function configureStore(onComplete) {
  const sagaMiddleware = createSagaMiddleware();
  let middlewares = [sagaMiddleware];
  if (process.env.NODE_ENV !== "production") {
    // middlewares = [...middlewares, Logger];
    middlewares = [...middlewares];
  }
  let store = createStore(reducers, compose(applyMiddleware(...middlewares)));
  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require("./reducers").default;
      store.replaceReducer(nextRootReducer);
    });
  }
  sagaMiddleware.run(sagas);
  return store;
}
