import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as languages from './langs';
const resources = languages;
i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});
if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', 'en');
}
i18n.changeLanguage(localStorage.getItem('i18nextLng'));
i18n.on('languageChanged', () => {});
export default i18n;
