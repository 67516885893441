import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';

export const GraphTab = ({ type, labels, data, text, styles, label, unit }) => {
  let title = unit ? `${text} ( ${unit} )` : text;

  let newColor = [];

  if (type === 'pie') {
    labels.map((item, key) => {
      let temp = styles.find((style) => {
        return item === style.legend;
      });
      newColor[key] = temp.color;
      return null;
    });
  } else {
    data.map((item, key) => {
      let temp = styles.find((style) => {
        return item <= style.value;
      });
      newColor[key] = temp.color;

      return null;
    });
  }

  const state = {
    labels,
    datasets: [
      {
        // label: text,
        borderColor: 'black',
        borderWidth: 2,
        data,
        backgroundColor: newColor,
        hoverBackgroundColor: newColor,
      },
    ],
  };
  const generalOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: title,
        color: '#ff6600',
        font: {
          size: 16,
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <>
      {type === 'pie' ? (
        <>
          {/* <div className="chart-header">{text}</div> */}
          <Doughnut
            data={state}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                title: generalOptions.plugins.title,
              },
            }}
          />
        </>
      ) : (
        <>
          {/* <div className="chart-header" style={{ position: 'absolute' }}>
            <p>{text}</p>
          </div> */}
          <Bar height={320} width={400} data={state} options={generalOptions} />
        </>
      )}
    </>
  );
};

GraphTab.prototypes = {
  labels: PropTypes.array,
  data: PropTypes.array,
  text: PropTypes.string,
};
