/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';

import { Map, View, Overlay } from 'ol';
import { OSM, ImageWMS, XYZ } from 'ol/source';
import { Style, Stroke, Fill, Circle, Text } from 'ol/style';
import { WFS, GeoJSON } from 'ol/format';
import { equalTo as equalToFilter, and as andFilter } from 'ol/format/filter';
import { get } from 'ol/proj';

import {
  generatePopupHtml,
  generateStyles,
  generateProjectStyles,
  generateChartData,
  roundUpDecimals,
  generateWeatherPopup,
} from './helpers';
import { weatherLegend } from './Sidebar/constants';

import {
  firstLayer,
  commentsLayer,
  spiMeanLayer,
  crops,
  landscapeVector,
  landscapes,
  area0Layer,
  area1Layer,
  area2Layer,
  area3Layer,
  area4Layer,
  groupVector,
  vectorLayer,
  weather,
  weatherVector,
  matrixSet,
  geoserverUrl,
  format,
  defaultStyle,
  wfsUrl,
} from './layers';

export const Maps = ({ props }) => {
  const {
    area1,
    area2,
    area3,
    area4,
    selectedVariable,
    selectedLevel,
    actions,
    styles,
    selectedPillar,
    selectedType,
    country,
    showRaster,
    selectedCrop,
    selectedLand,
    selectedWeather,
    selectedSetting,
    selectedValidationVariable,
    selectedBankCode,
    monitorItem,
    monitor,
    selectedFertilizer,
    selectedData,
    filters,
  } = props;
  const mapContainer = useRef(null);

  const [shouldFetch, setShouldFetch] = useState(false);
  const [allFeatures, setAllFeatues] = useState([]);
  const [html, setHtml] = useState('');
  const [feature, setFeature] = useState(null);
  const [landscapeFeature, setLandscapeFeature] = useState(null);
  const [weatherFeature, setWeatherFeature] = useState(null);
  const [groupLegends, setGroupLegends] = useState([]);
  const [coord, setCoord] = useState();

  const [state, setState] = useState({
    firstLayer,
    commentsLayer: commentsLayer,
    spiMeanLayer,
    crops,
    landscapeVector,
    landscapes,
    area0Layer,
    area1Layer,
    area2Layer,
    area3Layer,
    area4Layer,
    groupVector: groupVector,
    vectorLayer: vectorLayer,

    weather,
    weatherVector,
  });
  var container = useRef(null);

  // Map initialization
  useEffect(() => {
    // create map object with feature layer
    var popup = new Overlay({
      element: container.current,
    });

    var map = new Map({
      overlays: [popup],

      target: mapContainer.current,
      layers: [
        // grayscaleMap,
        // newGrayScalLayer,
        firstLayer,
        // wpsLayer,
        commentsLayer,
        spiMeanLayer,
        weather,
        crops,
        landscapeVector,
        landscapes,
        weatherVector,
        groupVector,
        vectorLayer,
        area0Layer,
        area1Layer,
        area2Layer,
        area3Layer,
        area4Layer,
      ],
      view: new View({
        center: [29.94049072265625, -3.438720703125],
        zoom: 8.5,
        projection: get(matrixSet),
      }),
    });
    setState((prev) => {
      return {
        ...prev,
        map: map,
      };
    });

    map.on('pointermove', function (evt) {
      const { coordinate, pixel } = evt;

      let featuresAtPixel = map.getFeaturesAtPixel(pixel);
      setCoord(coordinate);

      if (featuresAtPixel.length) {
        map.forEachFeatureAtPixel(evt.pixel, (f, layer) => {
          const layerName = layer.get('name');
          if (layerName === 'Landscape') {
            popup.setPosition(coordinate);
            setLandscapeFeature(f);

            return null;
          }

          if (layerName === 'weather' || f.get('value') !== undefined) {
            popup.setPosition(coordinate);
            setWeatherFeature(f);
            setFeature();
            return null;
          }

          if (layerName === 'vector') {
            popup.setPosition(coordinate);
            setFeature(f);
            setWeatherFeature();
            return null;
          }
          popup.setPosition(undefined);
        });
      } else {
        popup.setPosition(undefined);
      }
    });

    map.on('singleclick', function (evt) {
      map.getLayers().forEach((layer) => {
        if (layer.get('name') === 'spi_mean') {
          if (layer.getVisible()) {
            let data = {
              x_coord: evt.coordinate[0],
              y_coord: evt.coordinate[1],
              layer: 'spi_mean',
            };
            popup.setPosition(evt.coordinate);
            let displayHtml = `<div>
                      <p>X : ${data.x_coord} </p>
                      </div><div>
                      <p>Y : ${data.y_coord} </p>
                    <div>`;
            setHtml(displayHtml);
            actions.getWeatherDataAtPixel(data);
          }
        }
      });
      map.forEachFeatureAtPixel(evt.pixel, (layer) => {
        if (layer.get('value')) {
          // conflict in country name ( BI || Burundi)
          let temp = {
            x_coord: layer.get('x_coord'),
            y_coord: layer.get('y_coord'),
          };

          actions.getRainFall(temp);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (selectedSetting.value === 2) {
      state.firstLayer.setSource(new OSM({}));
      state.commentsLayer.setVisible(false);
    } else if (selectedSetting.value === 1) {
      state.firstLayer.setSource(
        new XYZ({
          url: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
          crossOrigin: 'Anonymous',
        })
      );
      state.commentsLayer.setVisible(false);
    } else {
      state.commentsLayer.setVisible(true);
    }
  }, [selectedSetting.value]);

  useEffect(() => {
    state.crops.setVisible(showRaster);
  }, [showRaster]);

  useEffect(() => {
    if (feature) {
      const displayHtml = generatePopupHtml(
        feature,
        selectedData,
        selectedLevel,
        area4 ? 4 : area3 ? 3 : area2 ? 2 : area1 ? 1 : 0
      );

      setHtml(displayHtml);
    }
  }, [
    selectedVariable,
    selectedValidationVariable,
    feature,
    monitorItem,
    selectedFertilizer,
  ]);

  useEffect(() => {
    if (landscapeFeature) {
      const displayHtml = generatePopupHtml(
        landscapeFeature,
        selectedLand,
        selectedLevel,
        area4 ? 4 : area3 ? 3 : area2 ? 2 : area1 ? 1 : 0
      );

      setHtml(displayHtml);
    }
  }, [landscapeFeature]);

  useEffect(() => {
    if (weatherFeature) {
      let displayHtml = '';
      if (weatherFeature.get('province')) {
        displayHtml = generateWeatherPopup(
          weatherFeature,
          area4 ? 4 : area3 ? 3 : area2 ? 2 : area1 ? 1 : 0,
          selectedLevel
        );
      } else {
        const value = weatherFeature.get('value')
          ? roundUpDecimals(weatherFeature.get('value')) + ' mm'
          : 'Not reported';

        displayHtml = `
            <div>
              <p>Average rain : ${value}</p>
            </div>
            <div>
              <p>
                Location : ${coord[0].toFixed(5)}, ${coord[1].toFixed(5)}
              </p>
            </div>
            `;
      }
      setHtml(displayHtml);
    }
  }, [weatherFeature]);

  const generateFeatureRequest = (featureType, filter) => {
    let temp = new WFS().writeGetFeature({
      srsName: matrixSet,
      featureNS: geoserverUrl + '/umva_geo',
      featurePrefix: 'umva_geo',
      featureTypes: [featureType],
      outputFormat: 'application/json',
      filter: filter,
    });

    return temp;
  };

  useEffect(() => {
    if (selectedLand.value && selectedLevel) {
      state.landscapeVector.setVisible(true);
      let vectorSource = state.landscapeVector.getSource();
      var layer = 'area' + selectedLevel + '_landscape';

      // var showLayer = "area" + selectedLevel - 1;

      var featureRequest = generateFeatureRequest(layer, filters);
      fetch(wfsUrl, {
        method: 'POST',
        body: new XMLSerializer().serializeToString(featureRequest),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          var features = new GeoJSON().readFeatures(json);

          vectorSource.clear();
          vectorSource.addFeatures(features);

          state.map.getView().fit(vectorSource.getExtent());
        })
        .catch((e) => {
          console.log('Error', e);
        });
    } else {
      state.landscapeVector.setVisible(false);
    }
  }, [selectedLand.value, selectedLevel]);

  useEffect(() => {
    actions.setRainFall();
    if (selectedLevel) {
      state.area0Layer.setVisible(true);
      state.area1Layer.setVisible(false);
      state.area2Layer.setVisible(false);
      state.area3Layer.setVisible(false);
      state.area4Layer.setVisible(false);

      let layername = `area${selectedLevel - 1}Layer`;

      let filter = null;
      if (area1) {
        filter = `name_1 = '${area1.value}'`;
      }
      if (area2) {
        filter = `name_2 = '${area2.value}'`;
      }
      if (area3) {
        filter = `name_3 = '${area3.value}'`;
      }
      var filterParams = {
        FILTER: null,
        CQL_FILTER: filter,
        FEATUREID: null,
      };

      if (selectedLevel - 1) {
        state[layername].getSource().updateParams(filterParams);
        state[layername].setVisible(true);
      }
    }

    if (country) {
      setShouldFetch(true);

      state.vectorLayer.setVisible(true);
      let vectorSource = state.vectorLayer.getSource();
      var featureRequest;

      let layer = 'area' + selectedLevel;
      if (selectedPillar.value) {
        layer += '_' + selectedPillar.value;
      }
      if (selectedBankCode.value && !area4.value) {
        layer += '_' + selectedBankCode.value;
      }
      if (monitor.value && !area4.value) {
        layer += '_' + monitor.value;
      }
      if (selectedFertilizer.column_name) {
        layer += '_fertilizer_data';
      }
      featureRequest = generateFeatureRequest(layer, filters);
      fetch(wfsUrl, {
        method: 'POST',
        body: new XMLSerializer().serializeToString(featureRequest),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          setAllFeatues(json.features);

          var features = new GeoJSON().readFeatures(json);

          vectorSource.clear();
          vectorSource.addFeatures(features);

          // if (!selectedWeather.label) {
          state.map.getView().fit(vectorSource.getExtent());
          // }
        })
        .catch((e) => {
          console.log('Error', e);
        });
    }
  }, [
    area1,
    area2,
    area3,
    area4,
    selectedLevel,
    selectedPillar,
    selectedBankCode,
    monitor,
    selectedFertilizer,
  ]);

  useEffect(() => {
    const { groupVector } = state;
    if (area4.value) {
      setGroupLegends([]);

      let vectorSource = groupVector.getSource();

      var filter = equalToFilter('name_4', area4.value);
      var layer = 'group_by_colline_with_geom';

      // separate layers according to bank code remaining

      var featureRequest = generateFeatureRequest(layer, filter);
      let values = [];
      fetch(wfsUrl, {
        method: 'POST',
        body: new XMLSerializer().serializeToString(featureRequest),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          var groupFeatures = json.features;
          setAllFeatues(groupFeatures);
          var features = new GeoJSON().readFeatures(json);

          vectorSource.clear();
          vectorSource.addFeatures(features);

          // state.map.getView().fit(vectorSource.getExtent());
          if (
            groupFeatures.length > 0 &&
            selectedValidationVariable.column_name
          ) {
            groupFeatures.map((item) => {
              let data =
                item.properties[selectedValidationVariable.column_name];
              if (data) {
                values.push(data);
              }
              return null;
            });
            let legends = generateStyles(values, 'number');
            setGroupLegends(legends);
            actions.setStyles(legends);
          }
        })
        .catch((e) => {
          console.log('Error', e);
        });
    } else {
      groupVector.setVisible(false);
    }
  }, [area4.value, selectedValidationVariable.column_name]);

  useEffect(() => {
    if (groupLegends.length > 0) {
      state.groupVector.setVisible(true);
      state.groupVector.setStyle(function (feature) {
        var value = feature.get(selectedValidationVariable.column_name);
        let color = '#bdbdbd';
        let radius = 10;

        if (value) {
          let temp = groupLegends.find((item, key) => {
            radius = 15 + key * 5;
            return value <= item.value;
          });
          if (temp) {
            color = temp.color;
          }
        }

        const stroke = new Stroke({ color, width: 1 });
        var style = [
          new Style({
            image: new Circle({
              fill: new Fill({
                color,
              }),
              stroke,
              radius,
            }),
            text: new Text({
              font: 'bold 11px "Open Sans", "Arial Unicode MS", "sans-serif"',
              fill: new Fill({
                color: '#000',
              }),
              text: value ? String(value) : 0,
            }),
          }),
        ];
        return style;
      });
    } else {
      state.groupVector.setVisible(false);
    }
  }, [groupLegends.length]);

  useEffect(() => {
    generateChartData(selectedData, allFeatures, selectedType, actions);
  }, [
    allFeatures,
    selectedVariable,
    selectedValidationVariable,
    monitorItem,
    selectedFertilizer,
  ]);

  useEffect(() => {
    const { column_name, data_type } = selectedData;
    if (allFeatures.length > 0 && column_name) {
      let values = [];
      allFeatures.map((item) => {
        let data = item.properties[column_name];
        if (data) {
          values.push(data);
        }
        return null;
      });
      let legends =
        selectedType.value === 'Project and activities'
          ? generateProjectStyles(values)
          : generateStyles(values, data_type);
      actions.setStyles(legends);
    }
  }, [
    allFeatures,
    selectedVariable.column_name,
    area1,
    area2,
    area3,
    area4,
    selectedLevel,
    selectedValidationVariable.column_name,
    monitorItem.value,
    selectedFertilizer.column_name,
  ]);

  useEffect(() => {
    if (
      country &&
      (selectedVariable.column_name ||
        selectedValidationVariable.column_name ||
        monitorItem.column_name ||
        selectedFertilizer.column_name)
    ) {
      state.vectorLayer.setVisible(true);
      let vectorSource = state.vectorLayer.getSource();
      var featureRequest;

      let layer = 'area' + selectedLevel;
      if (selectedVariable.column_name) {
        layer += '_' + selectedPillar.value;
      }
      if (selectedValidationVariable.column_name && !area4.value) {
        layer += '_' + selectedBankCode.value;
      }
      if (monitorItem.column_name && !area4.value) {
        layer += '_' + monitor.value;
      }
      if (selectedFertilizer.column_name) {
        layer += '_fertilizer_data';
      }
      featureRequest = generateFeatureRequest(layer, filters);
      if (shouldFetch) {
        fetch(wfsUrl, {
          method: 'POST',
          body: new XMLSerializer().serializeToString(featureRequest),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            if (!area4.value) {
              setAllFeatues(json.features);
            }

            var features = new GeoJSON().readFeatures(json);

            vectorSource.clear();
            vectorSource.addFeatures(features);

            state.map.getView().fit(vectorSource.getExtent());
          })
          .catch((e) => {
            console.log('Error', e);
          });

        setShouldFetch(false);
      }
    }
  }, [
    selectedVariable.column_name,
    selectedValidationVariable.column_name,
    monitorItem.column_name,
    selectedFertilizer.column_name,
  ]);

  const getStyle = (feature, propertyName, type, data) => {
    var value = feature.get(propertyName);
    if (selectedType.value === 'Project and activities') {
      let temp = styles.find((item) => value === item.legend);
      return temp ? temp.color : '';
    }

    if (type === 'weather') {
      let temp = data.find((item) => {
        return value <= item.value;
      });
      return temp.color;
    }
    if (value) {
      if (type === 'dropdown') {
        let temp = styles.find((item) => value === item.legend);
        return temp ? temp.color : '';
      } else {
        let temp = styles.find((item) => {
          return value <= item.value;
        });
        return temp ? temp.color : '';
      }
    } else return null;
  };

  useEffect(() => {
    const { column_name, data_type } = selectedData;

    if (styles.length && column_name) {
      state.vectorLayer.setStyle(function (feature) {
        let color = getStyle(feature, column_name, data_type);
        const newStyle = [
          new Style({
            stroke: new Stroke({
              // color: "#ff6600",
              width: 1,
            }),
            fill:
              !area4.value &&
              new Fill({
                color: color ? color : '#bdbdbd',
              }),
          }),
        ];

        return newStyle;
      });
    } else {
      state.vectorLayer.setStyle(function () {
        return defaultStyle;
      });
    }
  }, [styles]);

  useEffect(() => {
    if (selectedCrop.id) {
      let newRasterSource = new ImageWMS({
        ratio: 1,
        url: geoserverUrl + '/umva_geo/wms',
        params: {
          FORMAT: format,
          VERSION: '1.1.1',
          LAYERS: 'umva_geo:' + selectedCrop.id,
          exceptions: 'application/vnd.ogc.se_inimage',
        },
      });

      state.crops.setSource(newRasterSource);
    }
  }, [selectedCrop]);

  useEffect(() => {
    if (selectedLand.value) {
      let newRasterSource = new ImageWMS({
        ratio: 1,
        url: geoserverUrl + '/umva_geo/wms',
        params: {
          FORMAT: format,
          VERSION: '1.1.1',
          LAYERS: 'umva_geo:' + selectedLand.value,
          exceptions: 'application/vnd.ogc.se_inimage',
        },
      });

      state.landscapes.setSource(newRasterSource);

      state.landscapes.setVisible(true);
    } else {
      state.landscapes.setVisible(false);
    }
  }, [selectedLand.value]);

  useEffect(() => {
    if (selectedWeather.label) {
      state.vectorLayer.setVisible(true);
      if (selectedWeather.value === 'spi_mean') {
        state.weatherVector.setVisible(false);
        state.spiMeanLayer.setVisible(true);
        actions.setRainFall();
      } else {
        state.spiMeanLayer.setVisible(false);
        state.weatherVector.setVisible(true);

        let vectorSource = state.weatherVector.getSource();

        var layer = 'umva_geo:' + selectedWeather.layer;
        var filter = '';
        if (selectedWeather.layer === 'group_rain') {
          if (area1) {
            filter = equalToFilter('province', area1.value);
          }

          if (area2) {
            filter = andFilter(
              equalToFilter('commune', area2.value),
              equalToFilter('province', area1.value)
            );
          }

          if (area3) {
            filter = andFilter(
              equalToFilter('zone', area3.value),
              equalToFilter('commune', area2.value),
              equalToFilter('province', area1.value)
            );
          }
          if (area4) {
            filter = andFilter(
              equalToFilter('colline', area4.value),
              equalToFilter('zone', area3.value),
              equalToFilter('commune', area2.value),
              equalToFilter('province', area1.value)
            );
          }
        }

        var featureRequest = generateFeatureRequest(layer, filter);
        fetch(wfsUrl, {
          method: 'POST',
          body: new XMLSerializer().serializeToString(featureRequest),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            const all = json.features;

            var features = new GeoJSON().readFeatures(json);
            let values = [];
            all.map((item) => {
              let data = item.properties['value'];
              // if (data) {
              values.push(data);
              // }
              return null;
            });
            let legends = generateStyles(values, 'number', true);
            actions.setStyles(legends);
            vectorSource.clear();
            vectorSource.addFeatures(features);

            if (selectedWeather.value === 'observed_rainfall') {
              // state.vectorLayer.setVisible(false);

              state.weatherVector.setStyle((feature) => {
                let color = '#bdbdbd';
                const stroke = new Stroke({ color, width: 1 });
                var value = feature.get('value');

                let radius = 5;
                if (value) {
                  let temp = legends.find((item, key) => {
                    radius = 5 + key * 2;
                    return value <= item.value;
                  });
                  if (temp) {
                    color = temp.color;
                  }
                }
                var style = [
                  new Style({
                    image: new Circle({
                      fill: new Fill({
                        color,
                      }),
                      stroke,
                      radius,
                    }),
                  }),
                ];
                return style;
              });
            } else {
              actions.setRainFall();
              state.weatherVector.setStyle((feature) => {
                let color = getStyle(
                  feature,
                  'value',
                  'weather',
                  weatherLegend
                );
                const newStyle = [
                  new Style({
                    stroke: new Stroke({
                      color,
                      width: 1,
                    }),
                    fill: new Fill({
                      color,
                    }),
                  }),
                ];
                return newStyle;
              });
            }
          })
          .catch((e) => {
            console.log('Error', e);
          });
      }
    } else {
      state.weather.setVisible(false);
      state.weatherVector.setVisible(false);
      state.spiMeanLayer.setVisible(false);
    }
  }, [selectedWeather, selectedLevel, area1]);

  return (
    <>
      <div id="map" ref={mapContainer} className="map">
        <div id="popup" className="ol-popup" ref={container}>
          <div
            id="popup-content"
            dangerouslySetInnerHTML={{
              __html: html,
            }}
            className="popup-content"
          ></div>
        </div>
      </div>
    </>
  );
};
