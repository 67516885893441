import GeoActionTypes from './actionTypes';
const INITIAL_STATE = {
  address: [],
  addressKeys: [],
  area1: { list: [], selected: '' },
  area2: { list: [], selected: '' },
  area3: { list: [], selected: '' },
  area4: { list: [], selected: '' },

  bankCodeList: [],

  collines: { list: [], selected: {} },
  communes: { list: [], selected: {} },
  country: {
    list: [{ value: 'BI', label: 'Burundi', code: 'BI' }],
    selected: null,
  },
  crops: [],

  error: { code: '', message: '', field: '' },

  fertilizers: [],

  groups: { list: [], selected: {} },

  localities: { list: [], selected: {} },
  loading: false,

  provinces: { list: [], selected: {} },

  message: '',
  monitorMenus: [],
  monitor: { monitorItems: [] },
  monitorItem: { column_name: '' },

  rainFall: { values: [], labels: [], coords: {} },

  selectedBankCode: {},
  selectedCrop: {},
  selectedFertilizer: {},
  selectedLand: {},
  selectLevel: 'country',
  selectedMenu: '',
  selectedSetting: { label: 'Google Satellite', value: 1 },
  selectedValidationVariable: {},
  selectedWeather: {},
  spi_max: [],
  spi_min: [],

  weatherDataAtPixel: [],
  weatherDataParams: {},

  zones: { list: [], selected: {} },
};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GeoActionTypes.GEO_COUNTRY_SELECT_REQUEST:
      return { ...state, loading: true };
    case GeoActionTypes.GEO_COUNTRY_SELECT_SUCCESS:
      return {
        ...state,
        country: { ...state.country, selected: action.selected },
        selectLevel: 'country',
        loading: false,
      };
    case GeoActionTypes.GEO_COUNTRY_SELECTED:
      return {
        ...state,
        country: { ...state.country, selected: action.selected },
        area1: { list: [], selected: '' },
        area2: { list: [], selected: '' },
        area3: { list: [], selected: '' },

        area4: { list: [], selected: '' },
        selectLevel: 'country',
      };
    case GeoActionTypes.GEO_PROVINCE_LIST_REQUEST:
      return { ...state, loading: true };
    case GeoActionTypes.GEO_PROVINCE_LIST_SUCCESS:
      return {
        ...state,
        provinces: {
          list: action.list,
          selected: { ...state.provinces.selected },
        },
        loading: false,
      };
    case GeoActionTypes.GEO_PROVINCE_SELECTED:
      return {
        ...state,
        provinces: {
          list: [...state.provinces.list],
          selected: action.selected,
        },
        selectLevel: 'province',
        loading: false,
      };
    case GeoActionTypes.GEO_COMMUINE_LIST_REQUEST:
      return { ...state, loading: true };
    case GeoActionTypes.GEO_COMMUNE_LIST_SUCCESS:
      return {
        ...state,
        communes: {
          list: action.list,
          selected: { ...state.communes.selected },
        },
        loading: false,
      };
    case GeoActionTypes.GEO_COMMUNE_SELECTED:
      return {
        ...state,
        communes: { list: [...state.communes.list], selected: action.selected },
        selectLevel: 'commune',
        loading: false,
      };
    case GeoActionTypes.GEO_ZONE_LIST_REQUEST:
      return { ...state, loading: true };
    case GeoActionTypes.GEO_ZONE_LIST_SUCCESS:
      return {
        ...state,
        zones: { list: action.list, selected: { ...state.zones.selected } },
        loading: false,
      };
    case GeoActionTypes.GEO_ZONE_SELECTED:
      return {
        ...state,
        zones: { list: [...state.zones.list], selected: action.selected },
        selectLevel: 'zone',
        loading: false,
      };
    case GeoActionTypes.GEO_COLLINE_LIST_REQUEST:
      return { ...state, loading: true };
    case GeoActionTypes.GEO_COLLINE_LIST_SUCCESS:
      return {
        ...state,
        collines: {
          list: action.list,
          selected: { ...state.collines.selected },
        },
        loading: false,
      };
    case GeoActionTypes.GEO_COLLINE_SELECTED:
      return {
        ...state,
        collines: { list: [...state.collines.list], selected: action.selected },
        selectLevel: 'colline',
        loading: false,
      };
    case GeoActionTypes.GEO_LOCALITY_LIST_REQUEST:
      return { ...state, loading: true };
    case GeoActionTypes.GEO_LOCALITY_LIST_SUCCESS:
      return {
        ...state,
        localities: {
          list: action.list,
          selected: { ...state.localities.selected },
        },
        loading: false,
      };
    case GeoActionTypes.GEO_LOCALITY_SELECTED:
      return {
        ...state,
        localities: {
          list: [...state.localities.list],
          selected: action.selected,
        },
        selectLevel: 'locality',
        loading: false,
      };
    case GeoActionTypes.GEO_GROUP_LIST_REQUEST:
      return { ...state, loading: true };
    case GeoActionTypes.GEO_GROUP_LIST_SUCCESS:
      return {
        ...state,
        groups: { list: action.list, selected: { ...state.groups.selected } },
        loading: false,
      };
    case GeoActionTypes.GEO_GROUP_SELECTED:
      return {
        ...state,
        groups: { list: [...state.groups.list], selected: action.selected },
        selectLevel: 'group',
        loading: false,
      };

    case GeoActionTypes.GET_AREA1_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GeoActionTypes.GET_AREA1_SUCCESS:
      return {
        ...state,
        loading: false,
        area1: { ...state.area1, list: action.area1 },
        address: action.address,
      };

    case GeoActionTypes.SET_AREA1_SELECTED:
      return {
        ...state,
        area1: { ...state.area1, selected: action.data },
        area2: { list: [], selected: '' },
        area3: { list: [], selected: '' },

        area4: { list: [], selected: '' },
      };

    case GeoActionTypes.GET_AREA2_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GeoActionTypes.GET_AREA2_SUCCESS:
      return {
        ...state,
        loading: false,
        area2: { ...state.area2, list: action.area2 },
      };

    case GeoActionTypes.SET_AREA2_SELECTED:
      return {
        ...state,
        area2: { ...state.area2, selected: action.data },
        area3: { list: [], selected: '' },

        area4: { list: [], selected: '' },
      };

    case GeoActionTypes.GET_AREA3_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GeoActionTypes.GET_AREA3_SUCCESS:
      return {
        ...state,
        loading: false,
        area3: { ...state.area3, list: action.area3 },
      };

    case GeoActionTypes.SET_AREA3_SELECTED:
      return {
        ...state,
        area3: { ...state.area3, selected: action.data },

        area4: { list: [], selected: '' },
      };

    case GeoActionTypes.GET_AREA4_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GeoActionTypes.GET_AREA4_SUCCESS:
      return {
        ...state,
        loading: false,
        area4: { ...state.area4, list: action.area4 },
      };

    case GeoActionTypes.SET_AREA4_SELECTED:
      return {
        ...state,
        area4: { ...state.area4, selected: action.data },
      };

    case GeoActionTypes.GET_CROP_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GeoActionTypes.GET_CROP_SUCCESS:
      return {
        ...state,
        loading: false,
        crops: action.data,
      };

    case GeoActionTypes.GET_CROP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GeoActionTypes.GET_CROP_VALIDATION_LIST_REQ:
      return {
        ...state,
        loading: true,
      };

    case GeoActionTypes.GET_CROP_VALIDATION_LIST_SUC:
      return {
        ...state,
        loading: false,
        bankCodeList: action.data,
      };

    case GeoActionTypes.GET_CROP_VALIDATION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case GeoActionTypes.GET_AGRICOACH_ACTIVITY_MENUS_REQ:
      return {
        ...state,
        loading: true,
      };

    case GeoActionTypes.GET_AGRICOACH_ACTIVITY_MENUS_SUC:
      return {
        ...state,
        loading: false,
        monitorMenus: action.data,
      };

    case GeoActionTypes.GET_AGRICOACH_ACTIVITY_MENUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case GeoActionTypes.SET_CROPS:
      return {
        ...state,
        crops: action.data,
      };

    case GeoActionTypes.SET_SELECTED_CROP:
      return {
        ...state,
        selectedCrop: action.data,
      };

    case GeoActionTypes.SET_SELECTED_BANK_CODE:
      return {
        ...state,
        selectedBankCode: action.data,
      };

    case GeoActionTypes.SET_SELECTED_VALIDATION_VARIABLE:
      return {
        ...state,
        selectedValidationVariable: action.data,
      };

    case GeoActionTypes.SET_SELECTED_MENU:
      return {
        ...state,
        selectedMenu: action.data,
      };

    case GeoActionTypes.SET_SELECTED_LAND:
      return {
        ...state,
        selectedLand: action.data,
      };

    case GeoActionTypes.SET_SELECTED_WEATHER:
      return {
        ...state,
        selectedWeather: action.data,
      };

    case GeoActionTypes.SET_SELECTED_SETTING:
      return {
        ...state,
        selectedSetting: action.data,
      };

    case GeoActionTypes.GET_WEATHER_DATA_REQ:
      return {
        ...state,
      };

    case GeoActionTypes.GET_WEATHER_DATA_SUC:
      return {
        ...state,
        weatherDataAtPixel: action.data,
        spi_max: action.spi_max,
        spi_min: action.spi_min,
        weatherDataParams: action.params,
      };

    case GeoActionTypes.GET_RAIN_FALL_DATA_SUC:
      return {
        ...state,
        rainFall: action.data,
      };

    case GeoActionTypes.GET_RAIN_FALL_DATA_REQ:
      return {
        ...state,
      };

    case GeoActionTypes.SET_RAIN_FALL_DATA:
      return {
        ...state,
        rainFall: INITIAL_STATE.rainFall,
      };

    case GeoActionTypes.SET_WEATHER_DATA:
      return {
        ...state,
        weatherDataAtPixel: [],
      };

    case GeoActionTypes.SET_SELECTED_MONITOR:
      return {
        ...state,
        monitor: action.data ? action.data : INITIAL_STATE.monitor,
      };

    case GeoActionTypes.SET_SELECTED_MONITOR_ITEM:
      return {
        ...state,
        monitorItem: action.data ? action.data : INITIAL_STATE.monitorItem,
      };

    case GeoActionTypes.SET_SELECTED_FERTILIZER:
      return {
        ...state,
        selectedFertilizer: action.data || INITIAL_STATE.selectedFertilizer,
      };

    case GeoActionTypes.CLEAR_ALL:
      return {
        ...state,
        monitor: INITIAL_STATE.monitor,
        monitorItem: INITIAL_STATE.monitorItem,

        selectedBankCode: INITIAL_STATE.selectedBankCode,
        selectedCrop: INITIAL_STATE.selectedCrop,
        selectedLand: INITIAL_STATE.selectedLand,
        selectedSetting: INITIAL_STATE.selectedSetting,
        selectedValidationVariable: INITIAL_STATE.selectedValidationVariable,
        selectedWeather: INITIAL_STATE.selectedWeather,

        weatherDataAtPixel: INITIAL_STATE.weatherDataAtPixel,
      };

    case GeoActionTypes.GET_FERTILIZERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GeoActionTypes.GET_FERTILIZERS_SUCCESS:
      return {
        ...state,
        loading: false,
        fertilizers: action.data,
      };

    case GeoActionTypes.GET_FERTILIZERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
