import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../action';
import md5 from 'md5';
// import RoleSelector from "../../../Common/RoleSelector";
import LanguageSelctor from '../../../Common/LanguageSelctor';
// import Spinner from "../../../Theme/Components/Spinner/Spinner";
import Loader from '../../../Theme/Components/Loader/Loader';

import './login.css';
import { TextInput, Button } from '../../../NewComponents/index';
import history from '../../../config/history';

const Login = (props) => {
  const { t } = useTranslation();
  const [username, setUsename] = useState('');
  const [password, setPassword] = useState('');
  const handleUserName = (value) => {
    setUsename(value);
  };
  const handlePassword = (value) => {
    setPassword(value);
  };
  // const handleRole = (value) => {
  //   setRole(value);
  // };
  const loginAction = () => {
    props.actions.login({
      username: username,
      password: md5(password),
    });
  };

  const signupAction = () => {
    history.push('/signup');
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        loginAction();
      }
    };

    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [username, password]);

  return (
    <div className="container-new">
      {props.loading && <Loader />}
      <div className="login-card">
        <div className="image-container">
          <div className="logo">
            {/* <img src="/images/logo.png" className="image" alt="" /> */}
            {process.env.REACT_APP_UMVA_MODE === 'test' ? (
              <div className="test-img"></div>
            ) : process.env.REACT_APP_UMVA_MODE === 'demo' ? (
              <div className="demo-img"></div>
            ) : null}
          </div>
          <h2 className="title">{t('UMVA SIGN IN')}</h2>
        </div>
        <div>
          {!!props.message && <p className={'message'}>{props.message}</p>}
        </div>
        <div className="form">
          <LanguageSelctor />
          <TextInput
            placeholder={t('UMVA ID')}
            value={username}
            showLabel={true}
            onChange={(e) => handleUserName(e.target.value)}
            error={
              props.error.field === 'username'
                ? t(`${props.error.message}`)
                : ''
            }
            icon="/images/login/Group.svg"
          />
          <TextInput
            placeholder={t('Password')}
            value={password}
            showLabel={true}
            onChange={(e) => handlePassword(e.target.value)}
            error={
              props.error.field === 'password'
                ? t(`${props.error.message}`)
                : ''
            }
            type="password"
            icon="/images/login/key.svg"
          />
          <Button text={t('Login')} onClick={loginAction} />
        </div>

        <div className="bottom-part">
          <p>
            Don't have a UMVA ID?
            <br />
            It's free It's easy. Sign up now!
          </p>
          <Button
            text={t('Sign Up')}
            onClick={signupAction}
            style={{ width: '10em' }}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    error: state.auth.error ? state.auth.error : {},
    loading: state.auth.loading,
    message: state.auth.message,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        login,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(Login);
