import React from 'react';
import history from '../../config/history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../Components/Auth/action';
import { setLevel, changeRasterVisibility } from '../../Components/Map/action';
import {
  getCrops,
  setCrops,
  setSelectedCrop,
  setSelectedMenu,
  setSelectedLand,
  setSelectedWeather,
  setSelectedSetting,
  setWeatherDataAtPixel,
  clearAllGeoState,
  getCropValidationBankList,
  // getActivityMonitorMenus,
  setCropValidationBankList,
  setSelectedBankCode,
  setSelectedValidationVariable,
  setSelectedMonitor,
  setSelectedMonitorItem,
  setSelectedFertilizer,
  getFertilizers,
} from '../../Components/Geo/action';
import {
  pillarSelect,
  setFilteredVariableData,
  setChartData,
  typeSelect,
} from '../../Components/Cap/action';
import { clearAllMapState } from '../../Components/Map/action';
import NavBar from '../Components/Navbar';
import MenuBar from '../Components/MenuBar';
import Loader from '../Components/Loader/Loader';
const Index = (props) => {
  window.onpopstate = function () {
    history.go(1);
  };

  const handleLogout = () => {
    props.actions.logout();
    // props.actions.clearAllGeoState();
    // props.actions.clearAllMapState();
  };

  return (
    <div className="container">
      {props.loading && <Loader />}
      <NavBar
        className="navbar"
        logo={'/images/logo.png'}
        sitename="UMVA GEO"
        logout={() => handleLogout()}
        levels={props.levels}
        setLevel={props.actions.setLevel}
        level={props.selectedLevel}
      />
      {props.country ? (
        <MenuBar {...props} />
      ) : (
        <div style={{ height: '50px' }}></div>
      )}
      {props.children}
    </div>
  );
};
const mapStateToProps = (state) => {
  const { role } = state.auth;

  return {
    // role: state.auth.user ? state.auth.user.role : '',
    loading: state.auth.loading || state.geo.loading || state.cap.loading,
    selectData: state.map.selectedData,
    selectedPillar: state.cap.selectedPillar,
    country: state.geo.country.selected
      ? state.geo.country.selected.value
      : null,

    area1: state.geo.area1.selected.value,
    selectedLevel: state.map.selectedLevel,
    levels: state.map.levels,
    crops: state.geo.crops,
    bankCodeList: state.geo.bankCodeList,

    selectedMenu: state.geo.selectedMenu,
    role,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        logout,
        setLevel,
        pillarSelect,
        changeRasterVisibility,
        getCrops,
        getFertilizers,
        setCrops,
        setSelectedCrop,
        getCropValidationBankList,
        // getActivityMonitorMenus,
        setCropValidationBankList,
        setSelectedBankCode,
        setSelectedValidationVariable,

        setSelectedMenu,
        setSelectedLand,
        setSelectedWeather,
        setSelectedSetting,
        setFilteredVariableData,
        setChartData,
        setWeatherDataAtPixel,
        typeSelect,

        clearAllGeoState,
        clearAllMapState,

        setSelectedMonitor,
        setSelectedMonitorItem,
        setSelectedFertilizer,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(Index);
