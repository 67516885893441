import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, signup } from '../action';
import LanguageSelctor from '../../../Common/LanguageSelctor';
import Loader from '../../../Theme/Components/Loader/Loader';

import './login.css';
import { Button } from '../../../NewComponents/index';
import history from '../../../config/history';
import { PersonalInfo } from './Signup/PersonalInfo';
import { Questions } from './Signup/Questions';

const SignUp = (props) => {
  const { t } = useTranslation();

  const loginAction = () => {
    history.push('/');
  };

  const data = {
    personal_information: {
      first_name: '',
      last_name: '',
      email: '',
      user_password: '',

      dob: '',
      id_image: '',
      mobile: '',
      photo: '',
      organization_id: 66,
      type: '',
      profession: '',
      phone: '',
    },
    social_information: {
      company: 'AUXFIN-FOUNDATION',
      country: '',
      head_of_the_family: false,
      latitude: '',
      longitude: '',
    },
    farmer_information: {
      card_id: '',
      farmer_no: '',
      fields: [],
      kbs_reg_no: '',
    },
  };
  // registerByApp(params);
  // registerQuestionAns({ question, umva_id})
  const initialQuestions = [
    {
      id: 1,
      question: 'What are you planning to use UMVA-GEO for?',
      answer: '',
    },
  ];

  const [personalInfo, setPersonalInfo] = useState(data.personal_information);
  const [questions, setQuestion] = useState(initialQuestions);
  const [error, setError] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handlePersonalInfoChange = (e) => {
    e.persist();
    setPersonalInfo((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const [country, setCountry] = useState(data.social_information.country);

  const signupAction = () => {
    // const fields = Object.keys(data.personal_information);
    let isEmpty = false;

    if (!country) {
      isEmpty = true;
      setError((prev) => {
        return {
          ...prev,
          country: true,
          message: `Please enter country`,
        };
      });
    } else {
      isEmpty = false;

      setError((prev) => {
        return {
          ...prev,
          country: false,
          message: '',
        };
      });
    }

    const reuqiredFields = [
      'first_name',
      'last_name',
      'email',
      'user_password',
    ];
    reuqiredFields.map((field) => {
      if (!personalInfo[field]) {
        isEmpty = true;
        setError((prev) => {
          return {
            ...prev,
            [field]: true,
            message: `Please enter all fields`,
          };
        });
        return null;
      } else {
        setError((prev) => {
          return {
            ...prev,
            [field]: false,
            // message: '',
          };
        });
      }
    });

    if (!isEmpty) {
      if (personalInfo.email !== confirmEmail) {
        setError((prev) => {
          return {
            ...prev,
            email: true,
            message: 'Email and confirm email does not match',
          };
        });
        return null;
      } else {
        setError((prev) => {
          return {
            ...prev,
            email: false,
            message: '',
          };
        });
      }

      if (personalInfo.user_password !== confirmPassword) {
        setError((prev) => {
          return {
            ...prev,
            password: true,
            message: 'Password and confirm password does not match',
          };
        });
        return null;
      } else {
        setError((prev) => {
          return {
            ...prev,
            password: false,
            message: '',
          };
        });
      }

      if (personalInfo.user_password.length < 6) {
        setError((prev) => {
          return {
            ...prev,
            password: true,
            message: 'Password must have min 6 characters',
          };
        });
        return null;
      } else {
        setError((prev) => {
          return {
            ...prev,
            password: false,
            message: '',
          };
        });
      }
    }

    const registerParams = {
      ...data,
      personal_information: { ...data.personal_information, ...personalInfo },
    };

    if (!isEmpty && !error.message) {
      props.actions.signup(registerParams, questions);
    }
  };

  return (
    <div className="container-new">
      {props.loading && <Loader />}
      <div className="login-card signup">
        <div className="image-container">
          <div className="logo">
            <img
              src="http://geo.umva.org/images/geo-logo.png"
              className="image"
              alt=""
            />
            {process.env.REACT_APP_UMVA_MODE === 'test' ? (
              <div className="test-img"></div>
            ) : process.env.REACT_APP_UMVA_MODE === 'demo' ? (
              <div className="demo-img"></div>
            ) : null}
          </div>
          <h2 className="title">{t('UMVA SIGN UP')}</h2>
        </div>
        <div style={{ position: 'relative' }}>
          <div className="errorContainer">
            {!!error.message && <p className={'error'}>{error.message}</p>}
          </div>

          <div className="signup-form">
            <PersonalInfo
              personalInfo={personalInfo}
              handlePersonalInfoChange={handlePersonalInfoChange}
              error={error}
              confirmEmail={confirmEmail}
              setConfirmEmail={setConfirmEmail}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
            />

            <Questions
              questions={questions}
              setQuestion={setQuestion}
              company={data.social_information.company}
              country={country}
              setCountry={setCountry}
              error={error}
            />
          </div>
        </div>
        <div className="bottom-part">
          <div className="bottom-part-signup">
            <p>Already have a UMVA ID? Go to login</p>
            <Button
              text={t('Login')}
              onClick={loginAction}
              style={{ width: '10em' }}
            />
          </div>
          <div className="bottom-part-signup">
            <p></p>
            <Button
              text={t('Sign Up')}
              onClick={signupAction}
              style={{ width: '10em' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state.auth);
  return {
    error: state.auth.error ? state.auth.error : {},
    loading: state.auth.loading,
    message: state.auth.message,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        login,
        signup,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(SignUp);
