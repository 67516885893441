const MapActionTypes = {
  MAP_LAYER_CLICK: "map.layer.click",
  SELECT_MAP_LEVEL: "select.map.level",

  CHANGE_LEVELS: "change.levels",

  CHANGE_RASTER_VISIBILITY: "change.raster.visibility",

  SET_SELECTED_STAT: "set.selected.stat",

  CLEAR_ALL: "clear.all",
};
export default MapActionTypes;
