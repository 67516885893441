import React from 'react';
import Select from 'react-select';
import './style.scss';

const DropDown = ({
  options,
  onChange,
  placeholder,
  defaultValue,
  value,
  minWidth,
  ...rest
}) => {
  return (
    <div
      className="select-container"
      style={{ minWidth: minWidth ? minWidth : '11em', marginRight: '0' }}
    >
      <Select
        options={options}
        onChange={onChange}
        className="select"
        isSearchable={false}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        isOptionDisabled={(option) => option.isDisabled}
        {...rest}
      />
    </div>
  );
};

export default DropDown;
