import axios from 'axios';
const ownApi = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_OWN_URL + '/api',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  let api = axios.create(defaultOptions);
  //set Auth token for any request

  api.interceptors.request.use(
    function (config) {
      // const token = localStorage.getItem('token');
      // if(token){
      // config.headers.Authorization = token ? `Bearer ${token}` : '';
      // const tokenDecode = jwt.decode(token);
      //   console.log('token', tokenDecode);
      //   // this just all works to compare the total seconds of the created
      //   // time of the token vs the ttl (time to live) seconds
      //   const expTime = tokenDecode.exp;
      //   const currentTime = Math.round(new Date().getTime() / 1000);
      //   // console.table({ 'created': expTime, 'current': currentTime });
      //   if (currentTime > expTime) {
      //     console.log("Expired");
      //     delete config.headers.Authorization;
      //     localStorage.removeItem('token');
      //     localStorage.removeItem('user');
      //     history.replace("/");

      //   }
      // }
      // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9sb2dpbiIsImlhdCI6MTU1NjYyMTE0MywiZXhwIjoxNTU2NjI0NzQzLCJuYmYiOjE1NTY2MjExNDMsImp0aSI6ImRyalZMU0M3M0lpRERNdzAiLCJzdWIiOjQzMDEsInBydiI6ImI0OGYxNzM3MjI0ODZkZWRiYjc0Nzc3Mjg2ZDVmODBhOWEwYjUxZTkifQ.kTZwqzL0RsgcP1JBjLM_FG7AR7xviRVMd0e8Us87GWs';

      return config;
    },
    function (err) {
      // console.log('reqeuist', err);
      return Promise.reject(err);
    }
  );

  api.interceptors.response.use(
    function (response) {
      // const token = localStorage.getItem('token');
      // if(token)
      // {

      //   axios.get(process.env.REACT_APP_UMVA_API_URL+'/api/resetToken',{headers:{"Authorization":`Bearer ${token}`}}).then(function(response){
      //    localStorage.setItem('token',response.data.result);
      //   })
      // }

      return response;
    },
    function (error) {
      const {
        // config,
        response: { status },
      } = error;
      console.log('response', status);
      return Promise.reject(error);
    }
  );
  return api;
};
// export const Api = axios.create({
//     baseURL: config.APP_URL+'/api',
//     timeout: 12000,
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     }
//   });

export default ownApi();
