import AuthActionTypes from './actionType';
import { fork, take, put, call } from 'redux-saga/effects';
import { AuthService } from './service';
import history from '../../config/history';
// import jwt from "jsonwebtoken";
import { getIp } from '../lib/check-auth';
export function* loginUser() {
  while (true) {
    const { params } = yield take(AuthActionTypes.AUTH_LOGIN_REQUEST);
    const { data, error } = yield call(AuthService.loginUser, params);
    if (data) {
      // let token = data.result.token;
      let user = data.result.user;
      localStorage.setItem('token', data.result.token);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('role', user.role);
      getIp();
      yield put({
        type: AuthActionTypes.AUTH_LOGIN_SUCCESS,
        user: user,
        loading: false,
        error: null,
      });
      yield put({
        type: AuthActionTypes.AUTH_USER_DETAIL_REQUEST,
        umva_id: user.umva_id,
      });
      history.push('/home');
    } else {
      if (error.response) {
        yield put({
          type: AuthActionTypes.AUTH_LOGIN_FAIL,
          user: null,
          loading: false,
          error: error.response.data.error.message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      } else if (error) {
        yield put({
          type: AuthActionTypes.AUTH_LOGIN_CANCEL,
          user: null,
          loading: false,
          error: error,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      } else {
        yield put({
          type: AuthActionTypes.AUTH_LOGIN_CANCEL,
          user: null,
          loading: false,
          error: '',
        });
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      }
    }
  }
}
export function* getUserDetail() {
  while (true) {
    const { umva_id } = yield take(AuthActionTypes.AUTH_USER_DETAIL_REQUEST);
    let params = { umva_id: umva_id };
    const { data, error } = yield call(AuthService.userDetail, params);

    const { accounts } = data.result;

    let isGold = false;
    let localStorageRole = localStorage.getItem('role');

    let showBrarurdiDataFor = ['Value Chain Auditor', 'Auditor'];
    isGold = showBrarurdiDataFor.includes(localStorageRole);

    if (!isGold) {
      accounts.map((item) => {
        const { member_ship_level } = item;

        if (member_ship_level && member_ship_level.level === 'Gold') {
          isGold = true;
        }
        return null;
      });
    }
    if (data) {
      yield put({
        type: AuthActionTypes.AUTH_USER_DETAIL_SUCCESS,
        userDetail: data.result,
        role: isGold ? 'Gold' : null,
      });
    } else {
      yield put({
        type: AuthActionTypes.AUTH_LOGIN_CANCEL,
        user: null,
        loading: false,
        error: error,
      });
      localStorage.removeItem('token');
      localStorage.removeItem('role');
    }
  }
}
export function* logoutUser() {
  while (true) {
    yield take(AuthActionTypes.AUTH_LOGOUT_REQUEST);
    const { data, error } = yield call(AuthService.logoutUser);
    if (data.result) {
      yield put({
        type: AuthActionTypes.AUTH_LOGOUT_SUCCESS,
        user: null,
        loading: false,
        error: '',
      });
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      history.replace('/');
    } else {
      if (error.response) {
        yield put({
          type: AuthActionTypes.AUTH_LOGOUT_FAIL,
          user: null,
          loading: false,
          error: error.response.data.error.message,
        });
      }
      yield put({
        type: AuthActionTypes.AUTH_LOGOUT_FAIL,
        user: null,
        loading: false,
        error: '',
      });
    }
  }
}

export function* signUp() {
  while (true) {
    const { params } = yield take(AuthActionTypes.SIGN_UP_REQUEST);
    const { data, error } = yield call(AuthService.registerByApp, params);
    if (data) {
      yield put({
        type: AuthActionTypes.SIGN_UP_SUCCESS,
        data: data.result,
        message: data.message,
      });
      history.push('/');
    } else {
      if (error.message) {
        yield put({
          type: AuthActionTypes.SIGNUP_FAIL,
          error: error.message,
        });
      } else {
        yield put({
          type: AuthActionTypes.SIGNUP_FAIL,
          error,
        });
      }
    }
  }
}

export default function* AuthSaga() {
  yield fork(loginUser);
  yield fork(logoutUser);
  yield fork(getUserDetail);
  yield fork(signUp);
}
