import React from 'react';
import MenuButton from './MenuButton';
import { pillars } from './constants';

const MenuBar = (props) => {
  const { crops, actions, selectedMenu, bankCodeList } = props;
  const {
    pillarSelect,
    changeRasterVisibility,
    getCrops,
    setSelectedMenu,
    setFilteredVariableData,
    setChartData,
    getCropValidationBankList,
    // getActivityMonitorMenus,
    clearAllGeoState,
    setSelectedFertilizer,
    typeSelect,
    getFertilizers,
  } = actions;

  const handleMenuClick = (menu = '', pillar = {}) => {
    pillarSelect(pillar);
    changeRasterVisibility('hide');
    setFilteredVariableData([]);
    setChartData([]);
    setSelectedFertilizer({});

    clearAllGeoState();
    // typeSelect({});

    setSelectedMenu(menu);
    if (menu === 'Crop suitability' && !crops.length) {
      getCrops();
    }
    if (menu === 'Crop validation' && !bankCodeList.length) {
      getCropValidationBankList();
    }
    if (menu === 'Fertilizer orders') {
      getFertilizers();
    }
    // if (menu === 'Activity monitor') {
    //   getActivityMonitorMenus();
    // }
  };

  return (
    <ul className="menubar">
      {/* <li onClick={() => changeRasterVisibility()}>
        <MenuButton
          image={`/images/icons/border.png`}
          label={"Toggle Raster"}
        />
      </li> */}
      <li onClick={() => handleMenuClick('Settings')}>
        <MenuButton
          image={`/images/icons/border.png`}
          label={'Settings'}
          className={selectedMenu === 'Settings' ? 'selected' : ''}
        />
      </li>
      {pillars.map((pillar, key) => (
        <li onClick={() => handleMenuClick(pillar.label, pillar)} key={key}>
          <MenuButton
            className={pillar.label === selectedMenu ? 'selected' : ''}
            image={`/images/icons/${pillar.value}.svg`}
            id={pillar.value}
            label={pillar.label}
          />
        </li>
      ))}

      <li onClick={() => handleMenuClick('Weather', {})}>
        <MenuButton
          image={`/images/icons/weather.svg`}
          label={'Weather'}
          className={selectedMenu === 'Weather' ? 'selected' : ''}
        />
      </li>

      <li onClick={() => handleMenuClick('Landscape', {})}>
        <MenuButton
          image={`/images/icons/mountain.svg`}
          label={'Landcape'}
          className={selectedMenu === 'Landscape' ? 'selected' : ''}
        />
      </li>

      <li onClick={() => handleMenuClick('Crop suitability', {})}>
        <MenuButton
          image={`/images/icons/crop.svg`}
          label={'Crop suitability'}
          className={selectedMenu === 'Crop suitability' ? 'selected' : ''}
        />
      </li>

      {props.role === 'Gold' && (
        <>
          <li onClick={() => handleMenuClick('Crop validation', {})}>
            <MenuButton
              image={`/images/icons/value-chain.svg`}
              label={'Value Chain'}
              className={selectedMenu === 'Crop validation' ? 'selected' : ''}
            />
          </li>

          <li onClick={() => handleMenuClick('Activity monitor', {})}>
            <MenuButton
              image={`/images/icons/monitor.svg`}
              label={'Activity monitor'}
              className={selectedMenu === 'Activity monitor' ? 'selected' : ''}
            />
          </li>
        </>
      )}

      <li onClick={() => handleMenuClick('Fertilizer orders', {})}>
        <MenuButton
          image={`/images/icons/fertilizer.svg`}
          label={'Fertilizer orders'}
          className={selectedMenu === 'Fertilizer orders' ? 'selected' : ''}
        />
      </li>
    </ul>
  );
};
export default MenuBar;
