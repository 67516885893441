// import {Text} from "../../components/weatherForecast/weatherForecast.web";
// import i18n from "../i18n";
const fr = {
  label: 'Fr',
  value: 'fr',
  translation: {
    welcome_message:
      'Bonjour, {{name}}, vous êtes connecté en tant que {{role}}',
  },
};
export default fr;
