import axios from "axios";
import history from "./history";
import jwt_decode from 'jwt-decode';
import errorParse from "../Components/lib/error";
const Api = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_UMVA_NEPAL_SERVER + "/api",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  let api = axios.create(defaultOptions);
  //set Auth token for any request
  api.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        const tokenDecode = jwt_decode(token);
        const expTime = tokenDecode.exp;
        const currentTime = Math.round(new Date().getTime() / 1000);
        // console.table({ 'created': expTime, 'current': currentTime });
        if (currentTime > expTime) {
          console.log("Expired token");
          delete config.headers.Authorization;
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          history.replace("/");
        }
      }
      return config;
    },
    function (err) {
      console.log("err", errorParse(err));
      return Promise.reject(err);
    }
  );

  // api.interceptors.response.use(
  //   function (response) {
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //       axios
  //         .get(process.env.REACT_APP_UMVA_API_URL + "/api/resetToken", {
  //           headers: { Authorization: `Bearer ${token}` },
  //         })
  //         .then(function (response) {
  //           localStorage.setItem("token", response.data.result);
  //         });
  //     }
  //     return response;
  //   },
  //   function (error) {
  //     return Promise.reject(error);
  //   }
  // );
  return api;
};
// export const Api = axios.create({
//     baseURL: config.APP_URL+'/api',
//     timeout: 12000,
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     }
//   });
export default Api();
