import React from "react";
import classnames from "classnames";
import "./loader.scss";

const Loader = ({ className }) => (
  <div className={classnames("loader", className)}>
    <span className="loader__ball loader__ball--1" />
    <span className="loader__ball loader__ball--2" />
    <span className="loader__ball loader__ball--3" />
  </div>
);

export default Loader;
