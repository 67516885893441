import React from 'react';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { Bar } from 'react-chartjs-2';
import { generateStyles } from '../helpers';
import { weatherColors } from './constants';

export const RainChart = ({ setRainFall, rainFall }) => {
  const { labels, values, x_coord, y_coord } = rainFall;
  const data = values;
  let styles = generateStyles(data, 'number', true);

  let backgroundColor = [];
  data.map((item, key) => {
    let temp = styles.find((style) => {
      return item <= style.value;
    });
    backgroundColor[key] = temp.color;

    return null;
  });

  let text = `Average Rainfall in mm`;

  const state = {
    labels,
    datasets: [
      {
        data: data,
        // label: 'Observed Rain',
        fill: false,
        lineTension: 0.1,
        backgroundColor: weatherColors[3],
        borderColor: weatherColors[5],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: [text, ''],
        color: '#ff6600',
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'week',
        },
      },
    },
  };

  return (
    <div className="sidebar bottom-left rainChart">
      <div className="subtitle">{`( ${x_coord}, ${y_coord} )`}</div>
      <div
        className="close-btn"
        onClick={() => setRainFall()}
        style={{ top: '5px' }}
      >
        <i className="fa fa-times" />
      </div>
      <Bar data={state} options={options} datasetIdKey={Math.random()} />
    </div>
  );
};
