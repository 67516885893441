/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import ReactTooltip from 'react-tooltip';
import '../style.css';

const TextInput = ({
  id,
  error,
  showLabel,
  icon,
  value,
  placeholder,
  unit,
  noIcon,
  keyfilter,
  type,
  disabled,
  ...rest
}) => {
  const [inputClass, setInputClass] = useState('input-control');

  let [showPassword, setShowPassword] = useState(false);

  let pass_img = showPassword
    ? '/images/login/eye_opened.svg'
    : '/images/login/eye_closed.svg';

  useEffect(() => {
    if (error) {
      setInputClass('input-control input-error-control');
    } else {
      setInputClass('input-control');
    }
  }, [error]);
  let handlePasswordDisplay = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div style={{ marginTop: '5px', marginBottom: '5px' }}>
      {error && <p className={'error'}>{error}</p>}

      <div className={'input'}>
        {!noIcon && (
          <span className={'input-group-addon'}>
            <img src={icon} alt=""></img>
          </span>
        )}
        {type === 'textarea' ? (
          <textarea
            id={id}
            className={inputClass}
            keyfilter={keyfilter}
            autoComplete="false"
            disabled={disabled}
            rows={3}
            defaultValue={value}
            {...rest}
          ></textarea>
        ) : (
          <input
            type={showPassword ? 'text' : type}
            id={id}
            className={inputClass}
            value={value}
            placeholder={placeholder}
            keyfilter={keyfilter}
            autoComplete="false"
            disabled={disabled}
            {...rest}
          />
        )}
        {type === 'password' && value && (
          <div className="pass-eye-con">
            <a href="#" onClick={handlePasswordDisplay}>
              <img src={pass_img} alt=""></img>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  error: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  showLabel: PropTypes.bool,
};
TextInput.defaultProps = {
  showLabel: true,
};
export default TextInput;
