export const types = [
  {
    label: 'SDF',
    value: 'SDF',
    imgSrc: 'images/sdf.svg',
    display: 'sdf',
  },
  {
    label: 'Problems',
    value: 'Problem',
    imgSrc: 'images/problems.svg',
    display: 'problem',
  },
  {
    label: 'Opportunities',
    value: 'Opportunities',
    imgSrc: 'images/opportunity.svg',
    display: 'opportunity',
  },
  {
    label: 'Equipments',
    value: 'Equipments',
    imgSrc: 'images/equipments.svg',
    display: 'equipment',
  },
  {
    label: 'Facilities',
    value: 'Facility',
    imgSrc: 'images/facility.svg',
    display: 'facility',
  },
  {
    label: 'Project and Activities',
    value: 'Project and activities',
    imgSrc: 'images/project.svg',
    display: 'project',
  },
];

export const landscapes = [
  {
    label: 'Average rainfall sum',
    value: 'Precipitation_clipped',
    legend: 'Average rainfall sum',
    unit: 'mm/year',
    column_name: 'label_precipitation',
  },
  {
    label: 'Elevation',
    value: 'Elevation_clipped',
    legend: 'Eleveation',
    unit: 'm',
    column_name: 'label_elevation',
  },
  {
    label: 'Soil Type',
    value: 'SoilType',
    legend: 'Soil Type',
    // column_name: "label_soiltype",
  },
  {
    label: 'Land Cover',
    value: 'LandCover',
    legend: 'Vegetation index',
    legendName: 'LandCover',
    unit: '-',
    // column_name: "mean_landcover",
  },
  {
    label: 'Erodability',
    value: 'ErosionRaster',
    legend: 'Erodability',
    unit: 'MJ mm (ha h year) -1',
    column_name: 'label_erosion',
  },
];

export const weather = [
  {
    label: 'Observed Rainfall',
    value: 'observed_rainfall',
    // layer: 'avg_rainfall',
    layer: 'group_rain',

    showLegend: false,
  },
  {
    label: 'Today',
    value: 'today',
    layer: 'avg_weather_today',
    showLegend: true,
  },
  {
    label: 'Tommorow',
    value: 'tommorow',
    layer: 'avg_weather_tomorrow',
    showLegend: true,
  },
  {
    label: 'Rest of the week',
    value: 'rest of the week',
    layer: 'avg_weather_rest_of_week',
    showLegend: true,
  },

  {
    label: 'Drought/wetness index',
    value: 'spi_mean',
    layer: 'spi_mean',
    showLegend: false,
  },
];

export const settings = [
  { label: 'Google Satellite', value: 1 },
  { label: 'Open Street Map', value: 2 },
  { label: 'CAP data allocation uncertainty', value: 3 },
];

export const monitors = [
  // {
  //   label: 'AgriCoach',
  //   value: 'activity_monitor',
  //   monitorItems: [
  //     {
  //       label: 'Installations (#)',
  //       value: 'cropapi_histories',
  //       column_name: 'c_count',
  //       showTotal: 'sum',
  //     },
  //     {
  //       label: 'Activity (%)',
  //       value: 'activity',
  //       column_name: 'activity',
  //       showTotal: 'avg',
  //     },
  //   ],
  // },

  {
    label: 'Agricoach',
    value: 'agricoach_activity',
    monitorItems: [
      {
        label: 'All Installations (#)',
        value: 'installations',
        column_name: 'total_calls',
        showTotal: 'sum',
      },

      {
        label: 'A installations (#)',
        value: 'a_installations',
        column_name: 'a_count',
        showTotal: 'sum',
      },
      {
        label: 'B installations (#)',
        value: 'b_installations',
        column_name: 'b_count',
        showTotal: 'sum',
      },
      {
        label: 'C installations (#)',
        value: 'c_installations',
        column_name: 'c_count',
        showTotal: 'sum',
      },
      {
        label: 'Activity (%)',
        value: 'activity',
        column_name: 'activity',
        showTotal: 'avg',
      },
    ],
  },

  {
    isDisabled: false,
    label: 'NutritionCoach',
    value: 'measurements',
    monitorItems: [
      {
        label: 'Number of unique users (#)',
        description: '',
        value: 'no_of_unique_users',
        column_name: 'no_of_unique_users',
      },
      {
        label: 'Total number of children measured (#)',
        description: 'Total number of unique children measured',
        value: 'total_child_measured',
        column_name: 'total_child_measured',
        // showTotal: 'sum',
      },
      {
        label: 'Number of children recently measured (#)',
        description: 'Total number of unique children measured in last 4 weeks',
        value: 'recent_child_measured',
        column_name: 'recent_child_measured',
        showTotal: '',
      },
      {
        label: 'Percentage mall nourished (%)',
        description:
          'Percentage of children measured in the last 4 weeks in the red zone',
        value: 'percentage_mal_nourished',
        column_name: 'percentage_mal_nourished',
      },

      {
        label: 'Percentage at risk (%)',
        description:
          'Percentage of children measured in the last 4 weeks in the yellow zone',
        value: 'percentage_at_risk',
        column_name: 'percentage_at_risk',
      },
    ],
  },
];

export const statisticsTabs = [
  { label: 'Table View', value: 'table' },
  { label: 'Graph View', value: 'graph' },
  // { label: "Export Pdf", value: "export" },
];

export const colors = ['#feedde', '#fdbe85', '#fd8d3c', '#e6550d', '#a63603'];
export const weatherColors = [
  '#f7fbff',
  '#9ac8e1',
  '#529dcc',
  '#1c6cb1',
  '#08306b',
];

export const weatherLegend = [
  {
    imgUrl: '/images/weathericons/norain.png',
    label: 'No Rain',
    color: weatherColors[0],
    unit: '(0-2 mm)',
    value: 2,
  },
  {
    imgUrl: '/images/weathericons/lightrain.png',
    label: 'Light Rain',
    color: weatherColors[1],
    unit: '(2-8 mm)',
    value: 8,
  },
  {
    imgUrl: '/images/weathericons/mediumrain.png',
    label: 'Moderate Rain',
    color: weatherColors[2],
    unit: '(8-20 mm)',
    value: 20,
  },
  {
    imgUrl: '/images/weathericons/highrain.png',
    label: 'Heavy Rain',
    color: weatherColors[3],
    unit: '(20-30 mm)',
    value: 30,
  },
  {
    imgUrl: '/images/weathericons/vhighrain.png',
    label: 'Very Heavy Rain',
    color: weatherColors[4],
    unit: '(30+ mm)',
    value: 100,
  },
];
export const colorsQualitative = [
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#ffff99',
  '#b15928',
  '#000000',
];
export const chartColors = [
  '#336699',
  '#99CCFF',
  '#999933',
  '#666699',
  '#CC9933',
  '#006666',
  '#3399FF',
  '#993300',
  '#CCCC99',
  '#666666',
  '#FFCC66',
  '#6699CC',
  '#663366',
  '#9999CC',
  '#CCCCCC',
  '#669999',
  '#CCCC66',
  '#CC6600',
  '#9999FF',
  '#0066CC',
  '#99CCCC',
  '#999999',
  '#FFCC00',
  '#009999',
  '#99CC33',
  '#FF9900',
  '#999966',
  '#66CCCC',
  '#339966',
  '#CCCC33',
  '#003f5c',
  '#665191',
  '#a05195',
  '#d45087',
  '#2f4b7c',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#EF6F6C',
  '#465775',
  '#56E39F',
  '#59C9A5',
  '#5B6C5D',
  '#0A2342',
  '#2CA58D',
  '#84BC9C',
  '#CBA328',
  '#F46197',
  '#DBCFB0',
  '#545775',
];

export const userRoles = [
  { label: 'Open-data', roleId: 1 },
  { label: 'General licensed', roleId: 2 },
  { label: 'Auxfin', roleId: 3 },
  { label: 'Auxfin-M&E', roleId: 4 },
  { label: 'Specific Licensed', roleId: 5 },
];

export const newRoles = [
  { role_id: null, role_name: 'Expert', id: 0 }, //demo.expert
  { role_id: null, role_name: 'Master Activator', id: 1 }, // agent.prime
  { role_id: null, role_name: 'Super Activator', id: 2 }, //   agent.joseph
  { role_id: 19, role_name: 'Key Activator', id: 4 }, // agent.deo
  { role_id: null, role_name: 'Group Leader', id: 5, otherName: 'Agent' }, // son-kiv-gr2.pnseb
];

export const cropValidationVariables = [
  {
    label: 'Total quantity brought',
    column_name: 'total_quantity_brought',
    unit: 'KG',
  },
  {
    label: 'Average quantity brought',
    column_name: 'avg_qty_brought',
    unit: 'KG',
  },
  { label: 'Total rejected', column_name: 'total_rejected', unit: 'KG' },
  {
    label: 'Total quantity accepted',
    column_name: 'total_qty_accepted',
    unit: 'KG',
  },
  { label: 'Credit Seed', column_name: 'credit_seed', unit: 'KG' },
  {
    label: 'Total amount to be paid to farmer',
    column_name: 'total_amt_to_be_paid_to_farmer',
    unit: 'BIF',
  },
  {
    label: 'Total net paid to farmer',
    column_name: 'total_net_paid_to_farmer',
    unit: 'BIF',
  },
  {
    label: 'Amount Net paid to collector',
    column_name: 'amt_net_paid_to_collector',
    unit: 'BIF',
  },
  {
    label: 'Total paid to farmer and collector',
    column_name: 'total_paid_to_farmer_and_collector',
    unit: 'BIF',
  },
  {
    label: 'Average alfatoxin value',
    column_name: 'avg_alfatoxin_value',
    unit: 'Cppb',
  },
  {
    label: 'Average moisture value',
    column_name: 'avg_moisture_value',
    unit: 'kg/hl',
  },
];

export const fertilizerMenu = [
  {
    column_name: '',
    label: 'Orders this season',
    unit: '# of bags',
    prefix: 'curr_',
  },
  {
    column_name: '',
    label: 'Orders last season',
    prefix: 'prev_',
    unit: '# of bags',
  },
  {
    column_name: '',
    label: 'Total orders all past seasons',
    prefix: '',
    unit: '# of bags',
  },
];
