import ownApi from '../../config/ownApi';
import errorParse from '../lib/error';

function getCountry(params)
{
    const res = ownApi.get("/country", {params}).then(response => {

        
        return response;
    }).catch(error => { return errorParse(error) });
    return res;
}

function getProvinces(params)
{
    const res = ownApi.get("/provinces", {params}).then(response => {

        
        return response;
    }).catch(error => { return errorParse(error) });
    return res;
}
function getCommunes(params)
{
    const res = ownApi.get("/communes", {params}).then(response => {

        
        return response;
    }).catch(error => { return errorParse(error) });
    return res;
}
function getZones(params)
{
    const res = ownApi.get("/zones", {params}).then(response => {

        
        return response;
    }).catch(error => { return errorParse(error) });
    return res;
}
function getCollines(params)
{
    const res = ownApi.get("/collines", {params}).then(response => {

        
        return response;
    }).catch(error => { return errorParse(error) });
    return res;
}

function getLocalities(params)
{
    const res = ownApi.get("/localities", {params}).then(response => {

        
        return response;
    }).catch(error => { return errorParse(error) });
    return res;
}

function getGroups(params)
{
    const res = ownApi.get("/groups", {params}).then(response => {

        
        return response;
    }).catch(error => { return errorParse(error) });
    return res;
}
function getGisCapData(params)
{
    console.log('gis type',params);
    const res = ownApi.get("/getCapData", {params}).then(async(response) => {
        try
        {
            let rows=response.data;
            let row=null;
            for(row of rows)
            {
                //let dt=await ownApi.get('/gisByAddress?country='+params.country+'&province='+row.province+'&commune='+row.commune+'&zone='+row.zone+'&colline='+row.colline+'&locality='+row.locality+'&group='+row.group_);
            //    
                var latlng=[29.880611 ,-3.05933499999998];
                if(!row.latitude)
               {
                //    latlng = coordinates[Math.floor(Math.random() * coordinates.length)];
                var maxlng=30.1040;
                var minlng=29.9308;
                var maxlat=3.2402429999999;
                var minlat=3.3614;
                var lat = Math.random() * (+maxlat - +minlat) + +minlat; 
                var lng= Math.random()*(+maxlng - +minlng) + +minlng;
                latlng=[lng, -lat];
                //console.log('latlng',latlng);
                row.latitude=latlng[1];
                row.longitude=latlng[0];
              
                   
                }
               
           
        //    console.log('row', row);
            }
           
           console.log('data rows',rows);
           console.log('response',response)
           return response;
        }
        catch(e)
        {
            console.log(e);
            return errorParse(e);
            
        }
    }).catch(error => { return errorParse(error) });
    
    return res;
}
function getCoordinates(params)
{
    const res = ownApi.get("/gisByAddress", {params}).then(response => {

       
        return response;
    }).catch(error => { return errorParse(error) });
    
    return res;
}
export const GisService={getCountry,getGisCapData,getProvinces,getCommunes,getZones,getCollines,getLocalities,getGroups,getCoordinates}