/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Legends from '../Legend';
import { weatherLegend, statisticsTabs } from '../Sidebar/constants';

import { setSelectedStat } from '../../action';
import { setWeatherDataAtPixel, setRainFall } from '../../../Geo/action';
import { GraphTab } from '../GraphTab';
import { ModalChart } from '../Sidebar/ModalChart';
import {
  roundUpDecimals,
  getBooleanSdfHeader,
  getBooleanHeader,
} from '../helpers';
import { RainChart } from '../Sidebar/RainChart';

const CurrentLegend = (props) => {
  const [expand, setExpand] = useState(false);
  const geoserverUrl = process.env.REACT_APP_GEOSERVER_URL;

  const cropUrl =
    geoserverUrl +
    '/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=umva_geo:1&LEGEND_OPTIONS=fontSize:13;dx:10;fontColor:0x333333';

  const corretnessLegendUrl =
    geoserverUrl +
    '/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=umva_geo:area4_correctness';

  const spiMeanUrl =
    geoserverUrl +
    '/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=umva_geo:spi_mean&LEGEND_OPTIONS=fontSize:13;dx:5;fontColor:0x333333';

  const WeatherLegend = () => (
    <div>
      {weatherLegend.map((item, key) => {
        return (
          <Legends
            key={key}
            color={item.color}
            legend={item.label}
            imgUrl={item.imgUrl}
            value={item.unit}
          />
        );
      })}
    </div>
  );
  const {
    selectedVariable,
    selectedValidationVariable,
    monitorItem,
    selectedFertilizer,
    selectedLand,
    selectedStat,
    chartData,
    selectedType,
    styles,
    selectedBankCode,
    selectedCrop,
    selectedWeather,
    selectedSetting,
    weatherDataAtPixel,
    weatherDataParams,
    spi_max,
    spi_min,

    rainFall,
    filteredVariableData,

    actions,
  } = props;

  const legendName = selectedLand.legendName
    ? selectedLand.legendName
    : selectedLand.value;

  const handleExport = (filetype) => {};

  let btnClass = expand ? 'expand-btn rotated' : 'expand-btn';

  let bottomLeft = '';

  if (selectedStat === 'graph' && chartData.type === 'bar' && expand) {
    bottomLeft = 'sidebar bottom-left expanded';
  } else {
    bottomLeft = 'sidebar bottom-left shrinked';
  }

  // let selectedData = selectedVariable.label
  //   ? props.selectedVariable
  //   : { ...props.selectedValidationVariable, data_type: null };

  let selectedData = {};

  if (selectedVariable.column_name) {
    selectedData = selectedVariable;
  } else if (selectedValidationVariable.column_name) {
    selectedData = { ...selectedValidationVariable, data_type: 'number' };
  } else if (monitorItem.value) {
    selectedData = { ...monitorItem, data_type: 'number' };
  } else if (selectedFertilizer.column_name) {
    selectedData = { ...selectedFertilizer, data_type: 'number' };
  }

  const [minimize, setMinimize] = useState(false);
  const minimizeClass = `minimize-btn ${minimize ? 'arrowUp' : 'arrowDown'}`;
  let label =
    selectedData.data_type === 'boolean'
      ? selectedType.label === 'SDF'
        ? getBooleanSdfHeader(selectedData.label)
        : getBooleanHeader(selectedData.label, props.selectedType.display)
      : selectedData.label;
  const handleStatClick = (data) => {
    setMinimize(false);
    actions.setSelectedStat(data);
  };
  return (
    <Fragment>
      {selectedVariable.column_name && styles && styles.length > 0 && (
        <div id="legend" className="sidebar bottom customLegend">
          <div className="selected" style={{ marginBottom: '10px' }}>
            {label}
          </div>
          {styles.map((style, key) => {
            if (style.legend)
              return (
                <Legends color={style.color} legend={style.legend} key={key} />
              );
            return null;
          })}
          {selectedType.value !== 'Project and activities' && (
            <Legends color={'#bdbdbd'} legend={'Not Reported'} />
          )}
        </div>
      )}
      {selectedBankCode.value && selectedValidationVariable.column_name && (
        <div id="legend" className="sidebar bottom customLegend">
          <div className="selected">{selectedValidationVariable.label}</div>
          <div className="smallLabel" style={{ marginBottom: '10px' }}>
            {selectedBankCode.label}
          </div>

          {styles.map((style, key) => {
            if (style.legend)
              return (
                <Legends color={style.color} legend={style.legend} key={key} />
              );
            return null;
          })}
          {selectedType.value !== 'Project and activities' && (
            <Legends color={'#bdbdbd'} legend={'Not Reported'} />
          )}
        </div>
      )}

      {monitorItem.column_name && (
        <div id="legend" className="sidebar bottom weatherLegend">
          <div className="selected">
            <p>{monitorItem.label}</p>
          </div>
          {monitorItem.showTotal && (
            <div className="smallLabel" style={{ marginBottom: '10px' }}>
              {chartData.subLabel}
            </div>
          )}
          {styles.map((style, key) => {
            if (style.legend)
              return (
                <Legends color={style.color} legend={style.legend} key={key} />
              );
            return null;
          })}
        </div>
      )}

      {selectedFertilizer.column_name && (
        <div id="legend" className="sidebar bottom weatherLegend">
          <div className="selected">
            <p>
              {`${selectedFertilizer.label}`}
              {selectedFertilizer.unit ? ` (${selectedFertilizer.unit})` : ''}
            </p>
          </div>
          <div className="smallLabel" style={{ marginBottom: '10px' }}>
            {selectedFertilizer.subLabel}
          </div>
          {styles.map((style, key) => {
            if (style.legend)
              return (
                <Legends color={style.color} legend={style.legend} key={key} />
              );
            return null;
          })}
        </div>
      )}

      {selectedCrop.id && (
        <div id="legend" className="sidebar bottom weatherLegend">
          <div className="selected" style={{ marginBottom: '10px' }}>
            {selectedCrop.name}
          </div>
          <img src={cropUrl} alt="legend" />
        </div>
      )}

      {selectedLand.value && (
        <div id="legend" className="sidebar bottom weatherLegend">
          <div className="selected" style={{ marginBottom: '10px' }}>
            {selectedLand.legend}
            {selectedLand.unit ? ' (' + selectedLand.unit + ') ' : ''}
          </div>
          <img
            src={
              geoserverUrl +
              '/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=umva_geo:' +
              legendName +
              '&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x333333;fontSize:14;dx:10'
            }
            alt="legend"
          />
        </div>
      )}

      {selectedWeather.value && (
        <div className="sidebar bottom weatherLegend">
          <div className="selected" style={{ marginBottom: '10px' }}>
            {selectedWeather.label}
          </div>
          {selectedWeather.showLegend ? (
            <WeatherLegend />
          ) : (
            <>
              {selectedWeather.value === 'spi_mean' && (
                <>
                  <img src={spiMeanUrl} alt="legend" />
                </>
              )}
              {selectedWeather.value === 'observed_rainfall' && (
                <>
                  {styles.map((style, key) => {
                    if (style.legend)
                      return (
                        <Legends
                          color={style.color}
                          legend={style.legend}
                          key={key}
                        />
                      );
                    return null;
                  })}
                  {selectedType.value !== 'Project and activities' && (
                    <Legends color={'#bdbdbd'} legend={'Not Reported'} />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}

      {selectedSetting.value === 3 && (
        <div className="sidebar bottom">
          <div className="selected" style={{ marginBottom: '10px' }}>
            {selectedSetting.label}
          </div>
          <img src={corretnessLegendUrl} alt="legend" />
        </div>
      )}

      {weatherDataAtPixel.length > 0 && selectedWeather.value === 'spi_mean' && (
        <>
          <ModalChart
            weatherDataAtPixel={weatherDataAtPixel}
            weatherDataParams={weatherDataParams}
            spi_max={spi_max}
            spi_min={spi_min}
            classname={bottomLeft}
            setWeatherDataAtPixel={actions.setWeatherDataAtPixel}
          />
        </>
      )}

      {rainFall.values.length > 0 &&
        selectedWeather.value === 'observed_rainfall' && (
          <RainChart rainFall={rainFall} setRainFall={actions.setRainFall} />
        )}

      {selectedData.label && filteredVariableData.length > 0 && (
        <div className={bottomLeft} id="tabular-data">
          <div className="bottom-menus">
            <div
              className={minimizeClass}
              onClick={() => {
                setExpand(false);
                setMinimize((prev) => !prev);
              }}
            >
              <img src="/images/right_arrow.png" alt=""></img>
            </div>
            {statisticsTabs.map((item, key) => {
              let className = 'item';
              const checkSame = item.value === selectedStat;
              if (checkSame && !minimize) {
                className = 'item selected';
              }
              return (
                <div
                  key={key}
                  className={className}
                  onClick={() => handleStatClick(item.value)}
                >
                  {item.label}
                </div>
              );
            })}
            {!minimize && selectedStat === 'graph' && chartData.type === 'bar' && (
              <div
                className={btnClass}
                onClick={() => {
                  setExpand(!expand);
                }}
              >
                <img src="/images/right_arrow.png" alt=""></img>
              </div>
            )}
          </div>

          {/* Statistice part */}
          <div className={minimize ? 'hideStat' : 'showStat'}>
            {selectedStat === 'graph' && (
              <div className="table-container">
                <GraphTab {...chartData} label={label} styles={styles} />
              </div>
            )}
            {selectedStat === 'table' && (
              <div className="table-container">
                <table id="table-data" className="sidebar-table">
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <p>{chartData.text}</p>
                        {chartData.unit && (
                          <p className="smallLabel">( {chartData.unit} )</p>
                        )}
                        <p className="smallLabel">{chartData.subLabel}</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredVariableData.map((data, key) => (
                      <Fragment key={key}>
                        {data.parent && filteredVariableData.length > 1 && (
                          <tr className="parent">
                            <th colSpan="2">{data.parent}</th>
                          </tr>
                        )}
                        {data.child.map((data1, key1) => {
                          return (
                            <tr key={key1} className="child">
                              <th>{data1.address}</th>
                              <td>{roundUpDecimals(data1.value)}</td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          {selectedStat === 'export' && (
            <div
              className="table-container"
              onClick={() => handleExport('pdf')}
            >
              Click to export
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    weatherDataAtPixel,
    weatherDataParams,
    spi_max,
    spi_min,
    selectedBankCode,
    selectedValidationVariable,
    rainFall,
    monitor,
    monitorItem,
    selectedFertilizer,
  } = state.geo;

  const { styles, chartData } = state.cap;

  return {
    styles,
    chartData,
    selectedVariable: state.cap.selectedVariable,
    selectedType: state.cap.selectedType,
    selectedCrop: state.geo.selectedCrop,
    selectedLand: state.geo.selectedLand,
    selectedWeather: state.geo.selectedWeather,
    selectedSetting: state.geo.selectedSetting,
    filteredVariableData: state.cap.filteredVariableData,
    selectedStat: state.map.selectedStat,

    weatherDataAtPixel,
    weatherDataParams,
    spi_max,
    spi_min,

    selectedBankCode,
    selectedValidationVariable,

    rainFall,
    monitor,
    monitorItem,
    selectedFertilizer,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setSelectedStat,
        setWeatherDataAtPixel,
        setRainFall,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(CurrentLegend);
