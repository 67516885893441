import React from 'react';
import LanguageSelctorSmall from '../../Common/LanguageSelctorSmall';
import AppBreadCrumb from './AppBreadCrumb';
import DropDown from '../../NewComponents/Dropdown/DropDownRS';

const NavBar = (props) => {
  return (
    <div className={props.className}>
      <div
        className="content"
        onClick={() => {
          window.location.reload(false);
        }}
      >
        {/* <img className="logo" src={props.logo} alt="logo" /> */}
        <h1 className="sitename">{props.sitename}</h1>
      </div>

      <AppBreadCrumb levels={props.levels} />

      <div className="content">
        <DropDown
          options={props.levels}
          placeholder={'Select Level'}
          onChange={props.setLevel}
          value={props.level}
        />
        <LanguageSelctorSmall />
        <button className="logout" onClick={props.logout}>
          <img src={'images/icons/logout.svg'} alt="" />
        </button>
      </div>
    </div>
  );
};

export default NavBar;
