const CapActionTypes = {
  CAP_PILLAR_REQUEST: 'cap.pillar.request',
  CAP_PILLAR_SUCCESS: 'cap.pillar.success',
  CAP_PILLAR_FAIL: 'cap.pillar.fail',
  CAP_PILLAR_SELECT: 'cap.pillar.select',
  CAP_TYPE_SELECT: 'cap.type.select',
  CAP_DATA_LIST_REQUEST: 'cap.data.list.request',
  CAP_DATA_LIST_SUCCESS: 'cap.data.list.success',
  CAP_DATA_LIST_FAIL: 'cap.data.list.fail',
  CAP_SET_SELECED_DATA: 'cap.data.selected',
  CAP_SDF_DATA_REQUEST: 'cap.sdf.data.request',
  CAP_SDF_DATA_SUCCESS: 'cap.sdf.data.success',
  CAP_PROBLEM_DATA_REQUEST: 'cap.problem.data.request',
  CAP_PROBLEM_DATA_SUCCESS: 'cap.problem.data.success',
  CAP_PROJECT_DATA_REQUEST: 'cap.project.data.request',
  CAP_PROJECT_DATA_SUCCESS: 'cap.project.data.success',

  CAP_VARIABLES_REQUEST: 'cap.varirables.request',
  CAP_VARIABLES_SUCCESS: 'cap.varirables.success',
  CAP_VARIABLES_FAIL: 'cap.varirables.fail',

  SET_CAP_VARIABLES: 'set.cap.variables',

  GET_STYLES_REQ: 'get.styles.request',
  GET_STYLES_SUC: 'get.styles.success',
  GET_STYLES_FAIL: 'get.styles.fail',

  SET_STYLES: 'set.styles',

  SET_FILTERED_VARIABLE: 'set.filtered.variable',

  SET_CHART_DATA: 'set.chart.data',

  CLEAR_ALL: 'clear.all',
};
export default CapActionTypes;
