import Api from '../../config/ApiUmvaNepalServer';
import GeoApi from '../../config/GeoApi';

import errorParse from '../lib/error';
function getCountry(params) {
  const res = Api.get('/country')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function getProvinces(params) {
  const res = Api.get('/address/province/all', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function getCommunes(params) {
  const res = Api.get('/address/commune/all', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function getZones(params) {
  const res = Api.get('/address/zone/all', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function getCollines(params) {
  const res = Api.get('/address/colline/all', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function getLocalities(params) {
  const res = Api.get('/address/locality/all', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}
function getGroups(params) {
  const res = Api.get('/address/group/all', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function getArea1(params) {
  const res = GeoApi.get('/area1/get', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function getArea2(params) {
  const res = GeoApi.get('/area2/get', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function getArea3(params) {
  const res = GeoApi.get('/area3/get', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function getArea4(params) {
  const res = GeoApi.get('/area4/get', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

const getCrops = () => {
  const res = GeoApi.get('cropList')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
};

const getFertilizers = () => {
  const res = GeoApi.get('fertilizers')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
};

const getCropValidationBankList = () => {
  const res = GeoApi.get('getCropValidationBankList')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
};

const getActivityMonitorMenus = () => {
  const res = GeoApi.get('getActivityMonitorMenus')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
};

const getWeatherDataAtPixel = (params) => {
  const res = GeoApi.get('getWeatherDataAtPixel', { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return res;
};

const getRainData = (params) => {
  const res = GeoApi.get('getRainData', { params })
    .then(({ data }) => {
      return data;
    })
    .catch((e) => {
      return e;
    });
  return res;
};

export const GeoService = {
  getCountry,
  getProvinces,
  getCommunes,
  getZones,
  getCollines,
  getLocalities,
  getGroups,
  getArea1,
  getArea2,
  getArea3,
  getArea4,
  getCrops,
  getWeatherDataAtPixel,

  getCropValidationBankList,
  getRainData,
  getFertilizers,
  getActivityMonitorMenus,
};
