import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setLevel, changeLevels } from '../../Components/Map/action';

import {
  selectCountry,
  getArea1,
  setArea1,
  getArea2,
  setArea2,
  getArea3,
  setArea3,
  getArea4,
  setArea4,
} from '../../Components/Geo/action';
import { Dropdown } from '../../NewComponents';

const AppBreadCrumb = (props) => {
  // const {
  //   address_country,
  //   address_state,
  //   address_zone,
  //   address_zone1,
  //   address_city,
  // } = props.user_info;

  const handleCountryChange = (selectedCountry) => {
    props.actions.selectCountry(selectedCountry);

    props.actions.getArea1({ country: selectedCountry.label });
    props.actions.setLevel(props.levels[0]);
    let temp = props.levels.map((level) => {
      if (level.value < 3) {
        level['isDisabled'] = false;
      } else {
        level['isDisabled'] = true;
      }
      return level;
    });
    props.actions.changeLevels(temp);
  };
  const handleArea1Change = (selectedData) => {
    props.actions.setArea1(selectedData);

    props.actions.getArea2({
      area1: selectedData.value,
    });
    props.actions.setLevel(props.levels[1]);
    let temp = props.levels.map((level) => {
      if (level.value > 1 && level.value < 4) {
        level['isDisabled'] = false;
      } else {
        level['isDisabled'] = true;
      }
      return level;
    });
    props.actions.changeLevels(temp);
  };

  const handleArea2Change = (selectedData) => {
    props.actions.setArea2(selectedData);
    props.actions.getArea3({
      area2: selectedData.value,
      area1: props.area1.selected.value,
    });
    props.actions.setLevel(props.levels[2]);

    let temp = props.levels.map((level) => {
      if (level.value <= 2) {
        level['isDisabled'] = true;
      } else {
        level['isDisabled'] = false;
      }

      return level;
    });
    props.actions.changeLevels(temp);
  };
  const handleArea3Change = (selectedData) => {
    props.actions.setArea3(selectedData);
    props.actions.getArea4({
      area3: selectedData.value,
      area2: props.area2.selected.value,
      area1: props.area1.selected.value,
    });
    props.actions.setLevel(props.levels[3]);

    let temp = props.levels.map((level) => {
      if (level.value <= 3) {
        level['isDisabled'] = true;
      } else {
        level['isDisabled'] = false;
      }
      return level;
    });
    props.actions.changeLevels(temp);
  };

  const handleArea4Change = (selectedData) => {
    props.actions.setArea4(selectedData);
  };

  return (
    <div className="breadcrumb">
      <Dropdown
        options={props.country.list}
        onChange={handleCountryChange}
        placeholder={'Country'}
        value={props.country.selected}
        // isDisabled={address_country ? true : false}
      />

      {props.role === 'Gold' && (
        <>
          {props.area1.list.length > 0 && (
            <Dropdown
              options={props.area1.list}
              onChange={handleArea1Change}
              placeholder={props.address[0]}
              value={props.area1.selected}
              // isDisabled={address_state ? true : false}
            />
          )}
          {props.area2.list && props.area2.list.length > 0 && (
            <Dropdown
              options={props.area2.list}
              onChange={handleArea2Change}
              placeholder={props.address[1]}
              value={props.area2.selected}
              // isDisabled={address_zone ? true : false}
            />
          )}
          {props.area3.list && props.area3.list.length > 0 && (
            <Dropdown
              options={props.area3.list}
              onChange={handleArea3Change}
              placeholder={props.address[2]}
              value={props.area3.selected}
              // isDisabled={address_zone1 ? true : false}
            />
          )}
          {props.area4.list.length > 0 && (
            <Dropdown
              options={props.area4.list}
              onChange={handleArea4Change}
              placeholder={props.address[3]}
            />
          )}
        </>
      )}
    </div>
  );
};
AppBreadCrumb.propTypes = {
  data: PropTypes.object,
};
const mapStateToProps = (state) => {
  const { area1, area2, area3, area4 } = state.geo;
  const { role, userDetail } = state.auth;
  return {
    country: state.geo.country,
    address: state.geo.address,
    area1,
    area2,
    area3,
    area4,
    role,
    user_info: userDetail.user_info,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        selectCountry,

        getArea1,
        setArea1,

        getArea2,
        setArea2,

        getArea3,
        setArea3,

        getArea4,
        setArea4,

        setLevel,
        changeLevels,
      },
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchtoProps)(AppBreadCrumb);
