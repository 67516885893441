import CapActionTypes from './actionTypes';
export function getPillars() {
  return {
    type: CapActionTypes.CAP_PILLAR_REQUEST,
    loading: true,
  };
}
export function pillarSelect(params) {
  return {
    type: CapActionTypes.CAP_PILLAR_SELECT,
    params,
  };
}
export function typeSelect(params) {
  return {
    type: CapActionTypes.CAP_TYPE_SELECT,
    params,
  };
}
export function getDataList(params) {
  return {
    type: CapActionTypes.CAP_DATA_LIST_REQUEST,
    params,
  };
}
export function setDataSelected(params) {
  return {
    type: CapActionTypes.CAP_SET_SELECED_DATA,
    layers: params,
  };
}
export function getDataSdf(params) {
  return {
    type: CapActionTypes.CAP_SDF_DATA_REQUEST,
    params,
  };
}
export function getDataProblem(params) {
  return {
    type: CapActionTypes.CAP_PROBLEM_DATA_REQUEST,
    params,
  };
}
export function getDataProject(params) {
  return {
    type: CapActionTypes.CAP_PROJECT_DATA_REQUEST,
    params,
  };
}

export function getCapVariables(params) {
  return {
    type: CapActionTypes.CAP_VARIABLES_REQUEST,
    params,
  };
}

export function setCapVariables(params) {
  return {
    type: CapActionTypes.SET_CAP_VARIABLES,
    params,
  };
}

export function getStyles(params) {
  return {
    type: CapActionTypes.GET_STYLES_REQ,
    params,
  };
}

export function setStyles(params) {
  return {
    type: CapActionTypes.SET_STYLES,
    params,
  };
}

export function setFilteredVariableData(params) {
  return {
    type: CapActionTypes.SET_FILTERED_VARIABLE,
    params,
  };
}

export function setChartData(params) {
  return {
    type: CapActionTypes.SET_CHART_DATA,
    params,
  };
}
