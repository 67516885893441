import React from 'react';
import PropTypes from 'prop-types';
import '../style.css';

const Button = ({ id, name, text, className, icon, style, ...rest }) => {
  return (
    <button className="button" id={id} name={name} style={style} {...rest}>
      {icon && <i className={`${icon}`} style={{ padding: '5px' }} />}
      <span className="btn-text">{text}</span>
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
};
Button.defaultProps = {
  id: '',
  name: '',
  className: '',
  text: '',
};
export default Button;
