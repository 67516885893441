import AuthReducer from '../Components/Auth/AuthReducer';
import GisReducer from '../Components/Gis/reducer';
import CapReducer from '../Components/Cap/reducer';
import MapReducer from '../Components/Map/reducer';
import GeoReducer from '../Components/Geo/reducer';
import { combineReducers } from 'redux';
// export default combineReducers({
//   auth: AuthReducer,
//   gis: GisReducer,
//   cap: CapReducer,
//   map: MapReducer,
//   geo: GeoReducer,
// });

const appReducer = combineReducers({
  auth: AuthReducer,
  gis: GisReducer,
  cap: CapReducer,
  map: MapReducer,
  geo: GeoReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth.logout.request') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
