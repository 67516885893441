import React, { useState, useEffect } from "react";
import * as languages from "../trans/langs";
import i18n from "../trans/i18n";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../NewComponents";

const LanguageSelctor = ({ id, error, icon, value, placeholder, ...rest }) => {
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));
  useEffect(() => {
    localStorage.setItem("i18nextLng", language);
    i18n.changeLanguage(language);
  }, [language]);

  const handleChange = (e) => {
    setLanguage(e.value);
  };

  let { t } = useTranslation();
  let options = Object.values(languages);

  return (
    <div className="language-selector">
      <Dropdown
        options={options}
        onChange={handleChange}
        placeholder={t("Language")}
        defaultValue={languages[language]}
        // minWidth={"em"}
      />
    </div>
  );
};

export default LanguageSelctor;
