import React, { useState, useEffect } from 'react';
import * as languages from '../trans/langs';
import i18n from '../trans/i18n';
// import { Dropdown } from "../NewComponents";
import Select from 'react-select';

const LanguageSelctor = ({ id, error, icon, value, placeholder, ...rest }) => {
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));
  useEffect(() => {
    localStorage.setItem('i18nextLng', language);
    i18n.changeLanguage(language);
  }, [language]);

  const handleChange = (e) => {
    setLanguage(e.value);
  };

  let options = Object.values(languages);

  return (
    <div className="select-container" style={{ minWidth: '6em' }}>
      <Select
        options={options}
        onChange={handleChange}
        className="select"
        isSearchable={false}
        placeholder={placeholder}
        defaultValue={languages[language]}
        value={value}
        {...rest}
      />
    </div>
  );
};

export default LanguageSelctor;
