import React from "react";
import "./App.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./trans/i18n";
import { Provider } from "react-redux";
import store from "./config/store";
import UmvaRouter from "./config/UmvaRouter";
import "./Theme/layout.scss";
function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store()}>
        <UmvaRouter />
      </Provider>
    </I18nextProvider>
  );
}

export default App;
