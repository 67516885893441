 import AuthSaga from '../Components/Auth/AuthSaga';
import GisSaga from '../Components/Gis/saga';
import CapSaga from '../Components/Cap/saga';
import GeoSaga from '../Components/Geo/saga';
import { fork } from "redux-saga/effects";
export default function* Saga() {
     yield fork(AuthSaga);
     yield fork(GisSaga);
     yield fork(CapSaga);
     yield fork (GeoSaga)
}
