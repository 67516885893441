import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router';
import { bindActionCreators } from 'redux';
import { getUserDetail, setUser } from '../Components/Auth/action';
import Login from '../Components/Auth/Views/LoginNew';
import history from './history';
import Home from '../Components/Default/Views/Home';
import SignUp from '../Components/Auth/Views/SignUp';
import CustomRouter from './CustomRouter';

const UmvaRouter = (props) => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const tokenDecode = jwt_decode(token);
      const expTime = tokenDecode.exp;
      const currentTime = Math.round(new Date().getTime() / 1000);
      if (currentTime > expTime) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        history.replace('/');
      } else {
        const user = JSON.parse(localStorage.getItem('user'));
        props.actions.setUser(user);
        props.actions.getUserDetail(user.umva_id);
        history.replace('/home');
      }
    } else {
      history.replace('/');
    }
  }, [props.actions]);

  return (
    <CustomRouter history={history}>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </CustomRouter>
  );
};
const mapStateToProps = (state) => {
  const { country, area1, area2, area3 } = state.geo;
  return {
    userDetail: state.auth.userDetail,
    country,
    area1,
    area2,
    area3,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setUser,
        getUserDetail,
      },
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchtoProps)(UmvaRouter);
