import jwt_decode from 'jwt-decode';
import history from '../../config/history';
import Axios from 'axios';
export function checkAuthorization() {
  const token = localStorage.getItem('token');
  if (token) {
    const tokenDecode = jwt_decode(token);
    console.log('token', tokenDecode);
    const expTime = tokenDecode.exp;
    const currentTime = Math.round(new Date().getTime() / 1000);
    if (currentTime > expTime) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      history.replace('/');
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      this.props.actions.setUser(user);
      this.props.actions.getUserDetail(user.umva_id);
    }
  } else {
    history.replace('/');
  }
}
export async function getIp() {
  let geo = await Axios.get('http://www.geoplugin.net/json.gp');
  localStorage.setItem('country', geo.data.geoplugin_countryName);
}
