import CapActionTypes from "./actionTypes";
import { take, call, put, fork } from "redux-saga/effects";
import { CapService } from "./Service";
export const getProject = (state) => state.cap;
export function* getPillars() {
  while (true) {
    yield take(CapActionTypes.CAP_PILLAR_REQUEST);
    //console.log(params);
    const { data, error } = yield call(CapService.getPillars);

    if (!error) {
      console.log("data", data);
      yield put({
        type: CapActionTypes.CAP_PILLAR_SUCCESS,
        pillars: data.result,
      });
    }
  }
}

export function* getDataList() {
  while (true) {
    const { params } = yield take(CapActionTypes.CAP_DATA_LIST_REQUEST);
    const { data, error } = yield call(CapService.getDataList, params);
    if (!error) {
      yield put({
        type: CapActionTypes.CAP_DATA_LIST_SUCCESS,
        data: data.result,
      });
    }
  }
}

export function* getSdfDataById() {
  while (true) {
    const { params } = yield take(CapActionTypes.CAP_SDF_DATA_REQUEST);
    const { data, error } = yield call(CapService.getSdfData, params);
    if (!error) {
      console.log("saga", data);
      yield put({
        type: CapActionTypes.CAP_SDF_DATA_SUCCESS,
        data: data.data,
        sdf: data.sdfid,
        pillar: data.pillarid,
      });
    }
  }
}

export function* getProblemsDataById() {
  while (true) {
    const { params } = yield take(CapActionTypes.CAP_PROBLEM_DATA_REQUEST);
    const { data, error } = yield call(CapService.getProblemData, params);
    if (!error) {
      yield put({
        type: CapActionTypes.CAP_PROBLEM_DATA_SUCCESS,
        data: data.data,
        problem: data.problemid,
        pillar: data.pillarid,
      });
    }
  }
}

export function* getCapVariables() {
  while (true) {
    const { params } = yield take(CapActionTypes.CAP_VARIABLES_REQUEST);
    const { data, error } = yield call(CapService.getCapVariables, params);
    // console.log("data and error", data, error);
    if (!error) {
      yield put({
        type: CapActionTypes.CAP_VARIABLES_SUCCESS,
        variables: data.result,
      });
    } else {
      yield put({
        type: CapActionTypes.CAP_VARIABLES_FAIL,
        error: error,
      });
    }
  }
}

export function* getStyles() {
  while (true) {
    const { params } = yield take(CapActionTypes.GET_STYLES_REQ);
    const { data, error } = yield call(CapService.getStyles, params);
    if (!error) {
      yield put({
        type: CapActionTypes.GET_STYLES_SUC,
        data: data.result,
      });
    } else {
      yield put({
        type: CapActionTypes.GET_STYLES_FAIL,
        error: error,
      });
    }
  }
}

export default function* CapSaga() {
  //console.log('saga auth');
  yield fork(getPillars);
  yield fork(getDataList);
  yield fork(getSdfDataById);
  yield fork(getProblemsDataById);
  yield fork(getCapVariables);
  yield fork(getStyles);
}
